/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { StepSelector } from 'qonto/react/components/account-receivable/summary/step-selector/step-selector';

/**
 * @typedef {import("../../../../react/components/account-receivable/summary/step-selector/step-selector").StepSelectorProps} Step
 */

export default class FlowsAccountReceivableOnboardingSummary extends Component {
  stepSelector = StepSelector;

  @service intl;

  @action
  goTo(stepId) {
    this.args.context.nextStepId = stepId;
    this.args.transitionToNext();
  }

  /**
   * TODO: Replace with conditional based translated content, actual statuses and actions
   *
   * @returns {Array<Step>}
   */
  get steps() {
    return [
      {
        title: this.intl.t('receivable-invoices.onboarding.steps.numbering.title'),
        status: 'confirmed',
        subtitle: this.intl.t('receivable-invoices.onboarding.steps.numbering.subtitle.empty'),
        icon: 'invoice',
        onClick: () => this.goTo('numbering'),
      },
      {
        title: this.intl.t('receivable-invoices.onboarding.steps.company-details.title.company'),
        status: 'confirmed',
        subtitle: this.intl.t(
          'receivable-invoices.onboarding.steps.company-details.subtitle.empty'
        ),
        icon: 'company',
        onClick: () =>
          this.goTo(
            this.args.context.isItalianOrganization ? 'it-company-details' : 'company-details'
          ),
      },
      {
        title: this.intl.t('receivable-invoices.onboarding.steps.customization.title'),
        subtitle: this.intl.t('receivable-invoices.onboarding.steps.customization.subtitle.empty', {
          // TODO: Replace with actual legal country from context
          legalCountry: 'France',
        }),
        status: 'prefilled',
        icon: 'pole',
        onClick: () => this.goTo('customization-logo'),
      },
      {
        title: this.intl.t('receivable-invoices.onboarding.steps.accountant-access.title'),
        status: 'upgrade',
        subtitle: this.intl.t(
          'receivable-invoices.onboarding.steps.accountant-access.subtitle.empty'
        ),
        icon: 'calculator',
        onClick: () => {},
        disabled: true,
      },
    ];
  }
}
