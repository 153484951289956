export default {
  "details": "cgS",
  "details--ff": "cgc",
  "header-container": "cgq",
  "icon": "cgZ",
  "line-container": "cgR",
  "info-line": "cgQ",
  "body-details": "cge",
  "body-details-header": "cgB",
  "body-details-1": "cgE",
  "body-details-2": "cgd",
  "body-details-3": "cgI",
  "body-details-4": "cgt"
};
