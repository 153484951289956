export default {
  "details": "cqb",
  "disclaimer": "cqy",
  "data": "cqU",
  "confirm": "cqj",
  "form": "cqW",
  "textarea": "cqu",
  "preview": "cZS",
  "form-title": "cZc title1",
  "form-subtitle": "cZq title2",
  "divider": "cZZ",
  "submit": "cZR",
  "doc": "cZQ",
  "amount-input-error": "cZe"
};
