/* import __COLOCATED_TEMPLATE__ from './beneficiaries.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { dropTask, restartableTask, task, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
} from 'qonto/utils/transfers';

export default class FlowsTransfersFxNewBeneficiariesComponent extends Component {
  spinner = Spinner;

  @service abilities;
  @service beneficiariesManager;
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service segment;
  @service sensitiveActions;

  @tracked beneficiaries = [];
  @tracked isServerError = false;
  @tracked searchQuery = null;

  constructor() {
    super(...arguments);

    this.loadBeneficiariesTask.perform().catch(ignoreCancelation);

    let { origin } = this.args.context;
    this.segment.track('transfer_creation_swift-selected', { ...(origin && { origin }) });
  }

  get shouldDisplaySearchInput() {
    let isNoBeneficiaries =
      this.beneficiaries?.length === 0 &&
      !this.searchQuery &&
      !this.searchBeneficiaryTask.isRunning;

    return !isNoBeneficiaries || this.isServerError;
  }

  get shouldDisplayNewButton() {
    return this.abilities.can('create beneficiary') && !this.searchBeneficiaryTask.isRunning;
  }

  get shouldDisplaySearchEmptyState() {
    return this.beneficiaries?.length === 0 && this.searchQuery && !this.isServerError;
  }

  loadBeneficiariesTask = task(async () => {
    try {
      this.beneficiaries = await this.loadBeneficiaries();
    } catch {
      this.isServerError = true;
    }
  });

  searchBeneficiaryTask = restartableTask(async searchQuery => {
    try {
      await timeout(DEBOUNCE_MS);
      this.searchQuery = searchQuery;
      this.beneficiaries = await this.loadBeneficiaries(this.searchQuery);
    } catch {
      this.isServerError = true;
    }
  });

  loadBeneficiaries(searchQuery) {
    this.isServerError = false;
    let organizationId = this.args.context.fxTransfer.get('organization.id');
    return this.beneficiariesManager.loadFxBeneficiaries(organizationId, searchQuery);
  }

  @action
  selectBeneficiary(beneficiary) {
    let { fxTransfer, origin } = this.args.context;

    let currentBeneficiaryId = fxTransfer.get('beneficiary.id');
    if (beneficiary.id !== currentBeneficiaryId) {
      fxTransfer.setProperties({
        localAmount: 0,
        reference: null,
        fxPaymentPurpose: null,
        notifyByEmail: false,
        email: null,
      });
    }

    fxTransfer.set('beneficiary', beneficiary);

    copyBeneficiaryIntoTransfer(fxTransfer, beneficiary, {
      forceCopy: true,
    });
    copyBeneficiaryLabelsIntoTransfer(fxTransfer, beneficiary);

    this.segment.track('transfer-swift_beneficiary_selected', {
      ...(origin && { origin }),
    });

    this.args.transitionToNext();
  }

  @action
  transitionToBeneficiaryCreationFlow() {
    let { origin } = this.args.context;
    this.segment.track('transfer-swift_new-beneficiary_clicked', {
      ...(origin && { origin }),
    });
    // We reset the `beneficiaryToEdit` property in case we have already visited the beneficiary edition flow
    this.args.context.beneficiaryToEdit = null;
    this.args.pushForwardFlow('fx-transfer-beneficiary', 'add-beneficiary');
  }

  @action
  editBeneficiary(beneficiary) {
    let { origin } = this.args.context;
    this.segment.track('transfer-swift_edit-beneficiary_clicked', {
      ...(origin && { origin }),
    });
    this.args.context.beneficiaryToEdit = beneficiary;
    this.args.pushForwardFlow('fx-transfer-beneficiary', 'edit-beneficiary');
  }

  @action
  markBeneficiaryAsTrusted(beneficiary) {
    this.modals.open('beneficiary-trust-popup', {
      title: this.intl.t('transfers.beneficiaries.trust-popup.trust.title'),
      beneficiary,
      confirmTask: this.confirmMarkAsTrustedTask,
    });
  }

  @action
  removeTrustedMark(beneficiary) {
    this.modals.open('beneficiary-trust-popup', {
      title: this.intl.t('transfers.beneficiaries.trust-popup.untrust.title'),
      beneficiary,
      confirmTask: this.confirmRemovingTrustedMark,
    });
  }

  @action
  deleteBeneficiary(beneficiary) {
    this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.modals.delete-beneficiary.title'),
      description: this.intl.t('transfers.modals.delete-beneficiary.desc'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('transfers.modals.delete-beneficiary.confirm'),
      confirmTask: this.deleteBeneficiaryTask,
      beneficiary,
    });
  }

  confirmMarkAsTrustedTask = dropTask(async (close, { beneficiary }) => {
    try {
      await this.sensitiveActions.runTask.perform(this.markAsTrustedTask, beneficiary);
    } catch (error) {
      this.errors.handleError(error);
    } finally {
      close();
    }
  });

  markAsTrustedTask = task(async beneficiary => {
    try {
      await beneficiary.markAsTrusted();
      let message = this.intl.t('transfers.beneficiaries.trust-popup.trust.success');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      if (error?.errors[0].code === 'declined') {
        this.toastFlashMessages.toastError(
          this.intl.t('transfers.beneficiaries.trust-popup.trust.declined-error')
        );
      }

      throw error;
    }
  });

  confirmRemovingTrustedMark = task(async (close, { beneficiary }) => {
    try {
      await beneficiary.markAsUntrusted();
      this.toastFlashMessages.toastInfo(
        this.intl.t('transfers.beneficiaries.trust-popup.untrust.success')
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      close();
    }
  });

  deleteBeneficiaryTask = task(async (close, { beneficiary }) => {
    try {
      await beneficiary.destroyRecord();
      this.toastFlashMessages.toastInfo(
        this.intl.t('transfers.beneficiaries.delete.success-message')
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    } finally {
      close();
    }
  });
}
