export default {
  "fieldset": "cCO",
  "subscription-period": "cCm",
  "automatic-number-section": "cCp",
  "automatic-number-label": "cCx",
  "icon-tooltip": "cCw",
  "date-picker": "cCN",
  "label": "cCv",
  "header-text-field": "cCo",
  "header-text-field-container": "cCn",
  "header-text-field-add-button": "cCi btn btn--tertiary",
  "header-text-field-close-button": "cCA btn btn--icon-only btn--tertiary btn--small",
  "read-only-frequency": "cCY",
  "read-only": "cCh"
};
