export default {
  "page-wrapper": "Wx",
  "container": "Ww",
  "content": "WN",
  "showElement": "Wv",
  "illustration": "Wo",
  "fadeIn": "Wn",
  "scaleDown": "Wi",
  "error-content": "WA",
  "error-illustration": "WY"
};
