export default {
  "summary-description": "cEw",
  "detail": "cEN",
  "detail-value": "cEv",
  "account-icon": "cEo",
  "color-secondary": "cEn",
  "beneficiary-info": "cEi",
  "currency": "cEA",
  "beneficiary-name": "cEY",
  "separator": "cEh",
  "instant-subtitle": "cEf",
  "instant-wrapper": "cEK",
  "instant-header": "cEG",
  "instant-toggle": "cEr",
  "instant--disabled": "cEb",
  "limit-exceeded-disclaimer": "cEy",
  "sticky-panel": "cEU",
  "total-interest": "cEj",
  "tooltip-icon": "cEW",
  "tooltip": "cEu",
  "payment-options": "cdS"
};
