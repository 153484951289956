export default {
  "body-loading": "csF",
  "body-members": "csg",
  "body-details": "csD",
  "body-details-title": "csV",
  "icon": "csL",
  "body-details-header": "csT",
  "body-details-ids-subtitle": "csz",
  "body-details-overview-subtitle": "csa",
  "body-details-overview-content": "csH",
  "body-details-ctas": "csO",
  "body-details-ctas-left": "csm",
  "body-details-ctas-right": "csp",
  "right-edit": "csx"
};
