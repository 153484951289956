export default {
  "wrapper": "cRy",
  "confirm": "cRU",
  "plan-info": "cRj",
  "plan-info-left": "cRW",
  "info-title": "cRu body-1",
  "initial-trial-content": "cQS",
  "extra-fees-item": "cQc body-2",
  "extra-fees-container": "cQq",
  "bottom-wrapper": "cQZ",
  "total-price-container": "cQR",
  "extra-fees-element": "cQQ body-1",
  "border": "cQe",
  "subtitle": "cQB body-2",
  "group-item": "cQE",
  "amount": "cQd",
  "subscription-billing-summary": "cQI",
  "summary": "cQt",
  "summary-list": "cQM",
  "disclaimer": "cQP",
  "disclaimer-revamp": "cQl",
  "error": "cQX",
  "error-link": "cQC",
  "warning-wrapper": "cQk",
  "warning-label": "cQJ body-1"
};
