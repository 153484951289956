export default {
  "mt-16": "cgi",
  "title": "cgA",
  "edit-organization-form": "cgY",
  "beside-block": "cgh",
  "info-circle": "cgf",
  "hollow": "cgK",
  "filled": "cgG",
  "radio": "cgr",
  "cancel-button": "cgb",
  "error-message": "cgy"
};
