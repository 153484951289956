export default {
  "cell": "hc",
  "empty": "hq",
  "supplier": "hZ",
  "supplier-content": "hR",
  "supplier-avatar": "hQ mr-16",
  "installments": "he body-2",
  "overflow-hidden": "hB",
  "ellipsis": "hE",
  "next-installment-date": "hd",
  "next-installment-amount": "hI body-2",
  "amount": "ht",
  "disclaimer": "hM",
  "disclaimer-icon": "hP",
  "total-repayment": "hl body-1"
};
