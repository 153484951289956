/* import __COLOCATED_TEMPLATE__ from './cancellation-trial.hbs'; */
import { set, setProperties } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsSubscriptionChangeCancellationTrialComponent extends Component {
  @service intl;
  @service store;
  @service modals;
  @service segment;
  @service toastFlashMessages;
  @service subscriptionManager;
  @service organizationManager;

  @tracked previousPlan;

  @reads('subscriptionManager.currentPricePlan') currentPricePlan;
  @reads('subscriptionManager.currentSubscription') currentSubscription;

  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  constructor() {
    super(...arguments);
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }

  get currentPricePlanCode() {
    return this.currentPricePlan.code;
  }

  get activeTrial() {
    return this.currentSubscription?.activeTrial;
  }

  fetchDataTask = dropTask(async () => {
    if (this.hasModularPricing) {
      this.previousPlan = await this.store.findRecord(
        'subscription-product',
        this.activeTrial?.previous_product_id
      );
    } else {
      this.previousPlan = await this.store.findRecord(
        'price-plan',
        this.activeTrial?.previous_plan_id
      );
    }
  });

  confirmPlanTask = dropTask(async () => {
    let recurrence = this.activeTrial?.previous_recurrence;

    this.segment.track('plans_plan_clicked', {
      current_plan: this.currentPricePlanCode,
      target_plan: this.previousPlan?.code,
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
      recurrence,
    });

    let subscription;
    if (this.hasModularPricing) {
      subscription = this.store.createRecord('organization-subscription-new', {
        organization: this.organizationManager.organization,
        product: this.previousPlan,
      });
    } else {
      subscription = this.store.createRecord('organization-subscription', {
        organization: this.organizationManager.organization,
        pricePlan: this.previousPlan,
      });
    }

    subscription.recurrence = recurrence;
    set(this.args.context, 'recurrence', recurrence);

    set(this.args.context, 'selectedPricePlanCode', this.previousPlan);
    set(this.args.context, 'subscription', subscription);
    set(this.args.context, 'currentPricePlanCode', this.currentPricePlanCode);

    if (this.hasModularPricing) {
      try {
        let { warnings, total_estimate, extra, target_subscriptions } =
          await subscription.estimate();
        this._handleNextStep({
          warnings,
          targetSubscriptions: target_subscriptions,
          extraPrice: extra,
          estimatedPrice: total_estimate,
          subscription,
        });
      } catch ({ payload, status }) {
        if (status === 422) {
          let { warnings, errors, total_estimate, extra, target_subscriptions } = payload;
          let hasInsufficientFunds = errors.some(it => it.code === 'balance_insufficient_funds');
          let blockerErrors = errors.filter(it => it.code !== 'balance_insufficient_funds');
          this._handleNextStep({
            warnings,
            errors: blockerErrors,
            hasInsufficientFunds,
            targetSubscriptions: target_subscriptions,
            estimatedPrice: total_estimate,
            extraPrice: extra,
          });
        } else {
          let errorMessage = this.intl.t('toasts.errors.server_error');
          this.toastFlashMessages.toastError(errorMessage);
        }
      }
    } else {
      try {
        let { warnings, estimated_price } = await subscription.confirmFlight();

        this._handleNextStep({
          warnings,
          estimatedPrice: estimated_price,
        });
      } catch ({ payload, status }) {
        if (status === 422) {
          let { errors, warnings, estimated_price } = payload;
          let hasInsufficientFunds = errors.some(it => it.code === 'balance_insufficient_funds');
          let blockerErrors = errors.filter(it => it.code !== 'balance_insufficient_funds');
          this._handleNextStep({
            warnings,
            errors: blockerErrors,
            hasInsufficientFunds,
            estimatedPrice: estimated_price,
          });
        } else {
          let errorMessage = this.intl.t('toasts.errors.server_error');
          this.toastFlashMessages.toastError(errorMessage);
        }
      }
    }
  });

  _handleNextStep(args) {
    setProperties(this.args.context, args);
    this.args.transitionToNext();
  }
}
