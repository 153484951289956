/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SUBSCRIPTION_RECURRENCES, TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class FlowsSubscriptionChangeConfirmComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service intl;
  @service segment;
  @service subscriptionManager;
  @service organizationManager;

  constructor() {
    super(...arguments);
    this.sendTrackingEventOnLoad();
  }

  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  sendTrackingEventOnLoad() {
    let { context } = this.args;
    this.segment.track('plans_confirmation_displayed', {
      insufficient_funds: context.hasInsufficientFunds,
      target_plan: this.hasModularPricing
        ? context.subscription.product.get('code')
        : context.subscription.pricePlan.get('code'),
      recurrence: context.recurrence,
    });
  }

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;

  get targetSubscription() {
    return this.args.context.targetSubscriptions[0];
  }

  get extraPrice() {
    return this.args.context.extraPrice;
  }

  get currency() {
    if (this.hasModularPricing) {
      return this.args.context.estimatedPrice.vat_included_amount.currency;
    } else {
      return this.args.context.estimatedPrice.currency;
    }
  }

  get selectedPricePlan() {
    if (this.hasModularPricing) {
      return this.args.context.subscription.product;
    } else {
      return this.args.context.subscription.pricePlan;
    }
  }

  get currentPricePlan() {
    return this.subscriptionManager.currentPricePlan;
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get activeTrialMonthDuration() {
    return this.subscriptionManager.currentSubscription?.activeTrial.month_duration;
  }

  get intialTrialCallouts() {
    return [
      this.intl.t('subscription.change.confirmation.trial.callout:bullet-1', {
        newPlanName: this.selectedPricePlan.get('localName'),
        monthDuration: this.activeTrialMonthDuration,
      }),
      this.intl.t('subscription.change.confirmation.trial.callout:bullet-2', {
        previousPlanName: this.currentPricePlan.localName,
      }),
      this.intl.t('subscription.change.confirmation.trial.callout:bullet-3'),
    ];
  }

  get isDownsize() {
    return (
      this.args.context.subscription.recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY &&
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    );
  }

  get isUpsize() {
    return (
      this.args.context.subscription.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL &&
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY
    );
  }

  get showDisclaimerLegacyPricePlan() {
    return this.currentPricePlan.disabled;
  }

  get nextBillingDateFormatted() {
    return dateToken({
      date: this.estimatedPrice?.next_billing_date,
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-price-plans-confirm-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  get estimatedPrice() {
    return this.args.context.estimatedPrice;
  }

  @action
  onKeepCurrentPlan() {
    this.segment.track('plans_change_confirmation_rejected', {
      current_plan: this.currentPricePlan.get('code'),
      target_plan: this.selectedPricePlan.get('code'),
      recurrence: this.args.context.recurrence,
    });

    this.args.transitionToPrevious();
  }

  confirmationType(productCode) {
    let recurrence = this.args.context.recurrence;
    let isSamePlan = Boolean(this.subscriptionManager.getProduct(productCode));

    if (!isSamePlan) {
      return 'add';
    }
    if (recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY) {
      return 'downsize';
    }
    if (recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL) {
      return 'upsize';
    }
  }

  getTrackingInfo(targetPlanCode, currentPlanCode) {
    return {
      confirmation_type: this.confirmationType(targetPlanCode),
      current_plan_recurrence: this.subscriptionManager.currentSubscription?.recurrence,
      current_plan: currentPlanCode,
      target_plan: targetPlanCode,
      trial_state: this.subscriptionManager.currentSubscription?.hasInitialTrial
        ? TRACKING_TRIAL_STATE.INITIAL_TRIAL
        : TRACKING_TRIAL_STATE.NONE,
    };
  }

  savePlanTask = dropTask(async () => {
    if (this.hasModularPricing) {
      this.segment.track(
        'checkout_confirmation_cta_clicked',
        this.getTrackingInfo(this.selectedPricePlan.get('code'), this.currentPricePlan.get('code'))
      );
    } else {
      this.segment.track('plans_change_confirmation_clicked', {
        current_plan: this.currentPricePlan.get('code'),
        target_plan: this.selectedPricePlan.get('code'),
        trial_state: this.hasInitialTrial
          ? TRACKING_TRIAL_STATE.INITIAL_TRIAL
          : TRACKING_TRIAL_STATE.NONE,
        end_date: this.subscriptionManager.currentSubscription.activeTrial?.end_date,
        recurrence: this.args.context.recurrence,
      });
    }

    try {
      await this.args.context.subscription.save();
      await this.subscriptionManager.refresh();
      this.args.context.hasUpgradedPlan = true;
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      return error;
    }
  });
}
