export default {
  "wrapper": "nQ",
  "legend": "ne",
  "operation-code": "nB",
  "table": "nE",
  "footer": "nd",
  "footer-detraction": "nI",
  "footer-total-g": "nt",
  "footer-total-h": "nM",
  "footer-net-g-h": "nP"
};
