export default {
  "row": "hp",
  "cell": "hx",
  "cell-content": "hw",
  "supplier": "hN",
  "next-installment": "hv",
  "installment-amount": "ho",
  "total-repayment": "hn",
  "cell-details": "hi",
  "align-right": "hA",
  "empty": "hY"
};
