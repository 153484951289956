/* import __COLOCATED_TEMPLATE__ from './not-eligible-state.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

export default class FinancingPayLaterCockpitNotEligibleStateComponent extends Component {
  @service segment;
  @service router;
  @service zendeskLocalization;

  @action
  onPrimaryCtaClick() {
    this.segment.track('pay-later-partners_clicked', {
      origin: 'cockpit',
    });
    this.router.transitionTo('financing.partners');
  }

  @action
  onSecondaryCtaClick() {
    let href = this.zendeskLocalization.getLocalizedArticle('8271688');

    this.segment.track('pay-later-faq_clicked', {
      origin: 'cockpit_noneligible',
    });
    window.open(href, '_blank', 'noopener noreferrer');
  }
}
