export default {
  "cell": "Yx",
  "empty": "Yw",
  "supplier": "YN",
  "supplier-content": "Yv",
  "supplier-avatar": "Yo mr-16",
  "installments": "Yn body-2",
  "overflow-hidden": "Yi",
  "ellipsis": "YA",
  "completed-on": "YY",
  "financed-amount": "Yh body-2",
  "amount": "Yf",
  "total-repayment": "YK body-1"
};
