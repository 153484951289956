export default {
  "step-container": "cev",
  "content": "ceo",
  "beneficiary": "cen",
  "beneficiary-name": "cei",
  "trusted": "ceA",
  "amount-disclaimer": "ceY",
  "purpose-subtitle": "ceh",
  "purposes": "cef",
  "purpose-error": "ceK",
  "sticky-panel": "ceG"
};
