/* import __COLOCATED_TEMPLATE__ from './request-details.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask, restartableTask } from 'ember-concurrency';

import { apiBaseURL, requestsNamespace, requestsV4Namespace } from 'qonto/constants/hosts';
import { MILEAGE_VEHICLE_DETAILS_STORAGE_KEY } from 'qonto/constants/requests';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

const IBAN_ERROR_CODES = ['not_valid_iban', 'not_a_string', 'blank', 'invalid'];

export default class RequestDetailsComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;
  @service sentry;
  @service networkManager;
  @service organizationManager;
  @service segment;
  @service toastFlashMessages;
  @service abilities;
  @service modals;

  @tracked tripDate = this.args.context.requestMileage.tripDate;
  @tracked purpose = this.args.context.requestMileage.note;
  @tracked iban = this.args.context.requestMileage.iban;
  @tracked amount = null;
  @tracked rate = null;
  @tracked ibanEdited = false;
  @tracked amountLoading = false;
  @tracked amountRequestError = false;

  @tracked tripDateError = null;
  @tracked purposeError = null;
  @tracked ibanError = null;

  constructor() {
    super(...arguments);
    // We disable the eslint rule because errors are handled in the inner tasks
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.fetchAmountTask.perform();
  }

  get ibanDisclaimer() {
    if (!this.args.context.ibanExists) {
      return this.intl.t('requests.mileage.steps.request-details.iban.description');
    }
    if (this.ibanEdited) {
      return this.intl.t('requests.mileage.steps.request-details.iban.edited');
    }

    return '';
  }

  get vehicleInfo() {
    let { vehicles, requestMileage } = this.args.context;
    let { type, power, electric } = requestMileage.vehicle;
    let typeCopy = vehicles[type].label;
    let powerCopy = vehicles[type].powerOptions?.find(option => option.value === power).label;
    return {
      type: typeCopy,
      power: powerCopy,
      electric,
    };
  }

  get distanceKms() {
    return (this.args.context.requestMileage?.distanceMeters / 1000).toFixed(2);
  }

  get continueButtonLabel() {
    if (this.abilities.can('review mileage request')) {
      return this.intl.t('requests.mileage.steps.request-details.cta-admin');
    }

    return this.intl.t('requests.mileage.steps.request-details.cta');
  }

  @action
  handleChangeTripDate(date) {
    this.tripDate = date;
    this.args.context.requestMileage.tripDate = date;
  }

  @action
  handleChangePurpose(value) {
    this.purpose = value;
    this.args.context.requestMileage.note = value;
    // Reset purpose error
    this.purposeError = null;
  }

  @action
  handleChangeIban(value) {
    this.iban = value;
    this.args.context.requestMileage.iban = value;
    // Reset iban error
    this.ibanError = null;
    this.ibanEdited = true;
  }

  @action
  handleClickEditVehicle() {
    this.args.context.shouldGoToVehicleDetailsStep = true;
    this.segment.track('request_edit_vehicle_clicked', {
      origin: 'mileage_request',
    });
    this.args.transitionToNext();
  }

  fetchAmountTask = restartableTask(async () => {
    let { requestMileage } = this.args.context;
    this.amountLoading = true;
    try {
      let namespace = this.abilities.can('use mileage improvements request')
        ? requestsV4Namespace
        : requestsNamespace;

      let { organization } = this.organizationManager;

      let { id: organizationId } = organization;

      if (organization.isFrench && requestMileage.vehicle.electric === undefined) {
        requestMileage.vehicle.electric = false;
      }

      let vehicleData = requestMileage.vehicle ? { ...requestMileage.vehicle } : null;
      if (vehicleData && vehicleData.power === null) delete vehicleData.power;

      let response = await this.networkManager.request(
        `${apiBaseURL}/${namespace}/requests/mileages/calculate_amount`,
        {
          method: 'POST',
          headers: { 'X-Qonto-Organization-ID': organizationId },
          data: JSON.stringify({
            organization_id: organizationId,
            distance_meters: this.args.context.requestMileage.distanceMeters,
            ...(vehicleData && { vehicle: vehicleData }),
          }),
        }
      );

      this.amount = response.amount;
      this.rate = response.mileage_calculation.rate ?? response.mileage_calculation.rates[0];
      this.mileageCalculation = response.mileage_calculation;
      this.args.context.requestMileage.amount = response.amount;
    } catch (error) {
      // We don't want to handle this specific error
      // so we just log the error to sentry
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.amountRequestError = true;
    } finally {
      this.amountLoading = false;
    }
  });

  saveMileageRequestTask = dropTask(async () => {
    try {
      this.validate();
      if (this.ibanEdited) {
        await this.saveIbanTask.perform();
      }

      if (this.hasErrors) {
        return;
      }

      await this.args.context.requestMileage.save();

      this.segment.track('request_created', {
        request_type: 'mileage',
        role: this.organizationManager.membership.role,
      });

      if (this.args.context.requestMileage.vehicle) {
        this._saveVehichleDetailsLocalStorage(this.args.context.requestMileage.vehicle);
      }

      this.args.context.shouldGoToVehicleDetailsStep = false;
      this.args.transitionToNext();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.unexpected_error_occured'));
    }
  });

  saveIbanTask = dropTask(async () => {
    let { membership } = this.organizationManager;

    try {
      membership.iban = this.args.context.requestMileage.iban;

      if (!this.currentIban) {
        await membership.createIban();
      } else {
        await membership.updateIban();
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.handleIbanError(error);
    }
  });

  handleIbanError(error) {
    if (
      Array.isArray(error.errors) &&
      error.errors.some(({ detail }) => IBAN_ERROR_CODES.includes(detail.code))
    ) {
      this.ibanError = this.intl.t('validations.errors.invalid_iban');
    }
  }

  validate() {
    this.tripDateError = !this.tripDate ? this.intl.t('validations.errors.blank') : null;
    this.purposeError = !this.purpose ? this.intl.t('validations.errors.blank') : null;
    this.ibanError = !this.iban ? this.intl.t('validations.errors.blank') : null;
  }

  _saveVehichleDetailsLocalStorage(vehicle) {
    return safeLocalStorage.setItem(MILEAGE_VEHICLE_DETAILS_STORAGE_KEY, JSON.stringify(vehicle));
  }

  get hasErrors() {
    return Boolean(this.tripDateError || this.purposeError || this.ibanError);
  }

  get shouldHideCalculationDetails() {
    let { organization } = this.organizationManager;
    return this.abilities.can('use mileage improvements request') && organization.isFrench;
  }

  @action
  handleCalculationAmountClick() {
    this.segment.track('request_calculation-details_clicked', {
      origin: 'mileage_request',
    });

    this.modals.open('mileage/modals/mileage-calculation-details', {
      isFullscreenModal: true,
      amount: this.amount,
      mileageCalculation: this.mileageCalculation,
    });
  }
}
