/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { Button } from '@repo/design-system-kit';
import { CheckoutHeader, PriceBreakdown, SubscriptionHeader } from '@repo/domain-kit';
import { Addons } from 'qonto/constants/addons';
import type OrganizationManager from 'qonto/services/organization-manager';
import {
  type ContextArg,
  type EstimationWarning,
  type TargetSubscription,
  type TotalEstimate,
} from './dataContext.type';
// @ts-ignore
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';
import { billerBaseURL, billerV4Namespace } from 'qonto/constants/hosts';
// @ts-ignore
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-ignore
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsAddonChangeConfirmComponent extends Component<ContextArg> {
  @service intl!: any;
  @service networkManager!: any;
  @service sentry!: any;
  @service subscriptionManager!: any;
  @service organizationManager!: OrganizationManager;
  @service sensitiveActions!: any;
  @service toastFlashMessages!: any;
  @service segment!: any;

  subscriptionHeader = SubscriptionHeader;
  checkoutHeader = CheckoutHeader;
  priceBreakdown = PriceBreakdown;
  button = Button;
  currentPlan = this.subscriptionManager?.currentPricePlan;

  constructor() {
    // @ts-ignore
    super(...arguments);
    this.trackEventOnDisplay();
  }

  get addon(): TargetSubscription {
    return this.args.context.targetAddon;
  }

  get pricePlan(): TargetSubscription | undefined {
    return this.args.context.targetPricePlan;
  }

  get warnings(): EstimationWarning[] {
    return this.args.context.warnings || [];
  }

  get hasInitialTrial(): boolean {
    return this.subscriptionManager.currentSubscription.hasInitialTrial;
  }

  get trialDate(): string {
    return this.intl.formatDate(
      this.isArAddonTrial
        ? this.subscriptionManager.currentSubscription.arAddonTrial.endDate
        : this.subscriptionManager.currentSubscription.activeTrial.end_date,
      {
        month: 'short',
        day: '2-digit',
      }
    );
  }

  get total(): TotalEstimate {
    return this.args.context.total_estimate;
  }

  get totalBilling() {
    return this.intl.t('subscription.change.confirmation.summary.upsizing.subtitle', {
      date: this.formatDateLong(this.total.next_billing_date),
    });
  }

  get products(): { title: string; price: string }[] {
    return [
      ...(this.total.discount_amount.value !== 0
        ? [
            {
              title: this.intl.t('subscription.change.confirmation.discount'),
              price: this.formattedPrice(
                this.total.discount_amount!.value,
                this.total.discount_amount!.currency
              ),
            },
          ]
        : []),
      {
        title: this.intl.t('subscription.change.confirmation.vat'),
        price: this.formattedPrice(this.total.vat_amount.value, this.total.vat_amount.currency),
      },
    ];
  }

  formatDateLong(date: Date): string {
    return this.intl.formatDate(date, { format: 'long' });
  }

  trackEventOnDisplay() {
    let { hasInsufficientFunds, targetPricePlan, tracking } = this.args.context;
    this.segment.track('checkout_initialized');
    this.subscriptionManager.setTrackingUserProperties();
    this.segment.track('checkout_displayed_success', {
      current_plan: tracking.current_plan,
      target_plan: tracking.target_plan,
      trial_state: tracking.trial_state,
      insufficient_funds: !!hasInsufficientFunds,
      target_plan_recurrence: targetPricePlan?.recurrence || null,
      target_addon_code: tracking.addon_code,
      target_addon_recurrence: tracking.addon_recurrence,
    });
  }

  @action
  subtitle(
    recurrence: 'monthly' | 'annual',
    value: number,
    currency: string,
    isAddon = false
  ): string {
    if (this.isArAddonTrial && isAddon) {
      return recurrence === 'monthly'
        ? this.intl.t('subscription.change.confirmation.addon.trial.subtitle.month', {
            date: this.formatDateLong(
              this.subscriptionManager.currentSubscription.arAddonTrial.endDate
            ),
            price: this.formattedPrice(value, currency),
          })
        : this.intl.t('subscription.change.confirmation.addon.trial.subtitle.annual', {
            date: this.formatDateLong(
              this.subscriptionManager.currentSubscription.arAddonTrial.endDate
            ),
            price: this.formattedPrice(value, currency),
          });
    }
    if (this.hasInitialTrial) {
      return recurrence === 'monthly'
        ? this.intl.t(
            'subscription.change.confirmation.addon.billing.initial-trial.subtitle.monthly',
            { price: this.formattedPrice(value, currency) }
          )
        : this.intl.t(
            'subscription.change.confirmation.addon.billing.initial-trial.subtitle.annual',
            { price: this.formattedPrice(value, currency) }
          );
    }
    return this.intl.t('subscription.change.confirmation.addon.billing.subtitle', {
      amount: this.formattedPrice(value, currency),
    });
  }

  formattedPrice(value: number, currency: string): string {
    return this.intl.formatNumber(value, {
      currency,
      style: 'currency',
    });
  }

  get planTitle(): string {
    return this.pricePlan?.recurrence === 'monthly'
      ? 'subscription.change.confirmation.subscription.downsizing.title'
      : 'subscription.change.confirmation.subscription.upsizing.title';
  }

  get checklistBullet(): string {
    return this.pricePlan
      ? this.intl.t('subscription.change.confirmation.addon.trial.callout.bullet-4', {
          currentPlan: this.currentPlan.localName,
        })
      : this.intl.t('subscription.change.confirmation.addon.trial.callout.bullet-2');
  }

  get isArAddonTrial(): boolean {
    return (
      this.organizationManager.organization.hasArAddonForFree &&
      this.addon.product_code === Addons.AccountsReceivable &&
      !!this.subscriptionManager.currentSubscription.arAddonTrial
    );
  }

  @action
  onConfirm() {
    this.segment.track('checkout_confirmation_cta_clicked', this.args.context.tracking);
    this.sensitiveActions.runTask.perform(this.updateSubscriptionTask).catch(ignoreCancelation);
  }

  updateSubscriptionTask = dropTask(async () => {
    try {
      await this.networkManager.request(`${billerBaseURL}/${billerV4Namespace}/subscriptions`, {
        method: 'POST',
        data: {
          subscription: {
            organization_id: this.organizationManager.organization.organizationId,
            product_id: this.addon.product_id,
            recurrence: this.addon.recurrence,
          },
        },
      });
      await this.subscriptionManager.refresh();
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError((error as { errors?: any })?.errors)) {
        throw error;
      }
      this.segment.track('checkout_confirmation_error');

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      let errorMessage = this.intl.t('toasts.errors.generic');
      this.toastFlashMessages.toastError(errorMessage);
    }
  });
}
