export default {
  "wrapper": "cXl",
  "mobile-mode": "cXX",
  "logo": "cXC",
  "address-form": "cXk",
  "button-container": "cXJ",
  "address-form-header": "cXs",
  "address-form-content": "cXF",
  "address-form-address-search": "cXg",
  "ember-basic-dropdown": "cXD",
  "address-form-footer": "cXV",
  "submit-cta": "cXL",
  "form": "cXT",
  "city-section": "cXz",
  "title": "cXa",
  "mobile-title": "cXH"
};
