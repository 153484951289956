/* import __COLOCATED_TEMPLATE__ from './info.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import { trackedReset } from 'tracked-toolbox';

import { ROLES } from 'qonto/constants/membership';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class MemberInfo extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service abilities;
  @service errors;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service freeUsersDiscountManager;
  @service intl;
  @service modals;
  @service cardsManager;
  @service organizationManager;
  @service router;
  @service segment;
  @service store;
  @service sentry;
  @service subscriptionManager;
  @service featuresManager;

  @tracked team;
  @trackedReset({
    memo: 'args.member.last.value',
  })
  editingTeam = false;

  allowedBankAccountsPreviewLength = 3;
  allowedBankAccountsMaxLength = 20;

  get roleTranslations() {
    return {
      [ROLES.OWNER]: this.intl.t('roles.owner'),
      [ROLES.ADMIN]: this.intl.t('roles.admin'),
      [ROLES.MANAGER]: this.intl.t('roles.manager'),
      [ROLES.EMPLOYEE]: this.intl.t('roles.employee'),
      [ROLES.REPORTING]: this.intl.t('roles.reporting'),
    };
  }

  get shouldShowTeamPricingExperiment() {
    return (
      this.args.member?.last?.value?.role === ROLES.EMPLOYEE &&
      this.featuresManager.isEnabled('teamPlanPricingExperiment')
    );
  }

  get scopeTranslations() {
    return {
      team: this.intl.t('members.labels.team'),
      organization: this.intl.t('members.labels.company'),
    };
  }

  get permissionTranslations() {
    return {
      access_bank_accounts_statements: this.intl.t('members.labels.financial', { htmlSafe: true }),
      access_checks: this.intl.t('invitation-flow.permissions.expense.access-checks'),
      access_direct_debits: this.intl.t('invitation-flow.permissions.expense.access-direct-debits'),
      create_cards_and_review_request_cards: this.intl.t(
        'invitation-flow.permissions.expense.create-cards-and-review-request-cards'
      ),
      create_transfers_and_review_request_transfers: this.abilities.can('use mileages request')
        ? this.intl.t(
            'invitation-flow.permissions.expense.create-transfers-and-review-request-transfers-mileage'
          )
        : this.intl.t(
            'invitation-flow.permissions.expense.create-transfers-and-review-request-transfers'
          ),
      manage_bookkeepings: this.intl.t('members.labels.bookkeeping'),
      manage_memberships: this.intl.t('invitation-flow.permissions.team.manage-memberships'),
      manage_supplier_invoices: this.intl.t(
        'invitation-flow.permissions.expense.supplier-invoices'
      ),
      manage_teams: this.intl.t('invitation-flow.permissions.team.manage-teams'),
      access_team_transactions: this.intl.t('members.labels.account-transactions'),
      access_bank_accounts_balance: this.intl.t('members.labels.account-balances'),
    };
  }

  get customPermissions() {
    return this.args.member?.last?.value?.customPermissions;
  }

  get isLoading() {
    return this.args.member.isRunning;
  }

  get expensePermissions() {
    let customPermissions = this.args.member?.last?.value?.customPermissions;
    return {
      create_cards_and_review_request_cards:
        customPermissions?.groups.create_cards_and_review_request_cards,
      create_transfers_and_review_request_transfers:
        customPermissions?.groups.create_transfers_and_review_request_transfers,
    };
  }

  get expensePermissionsAfterSpendLimits() {
    let customPermissions = this.args.member?.last?.value?.customPermissions;
    return {
      access_direct_debits: customPermissions?.groups.access_direct_debits,
      access_checks: customPermissions?.groups.access_checks,
      manage_supplier_invoices: customPermissions?.groups.manage_supplier_invoices,
    };
  }

  get otherPermissions() {
    let customPermissions = this.args.member?.last?.value?.customPermissions;
    return {
      ...(customPermissions?.scope === 'organization'
        ? {
            manage_teams: customPermissions?.groups.manage_teams,
          }
        : { manage_memberships: customPermissions?.groups.manage_memberships }),
    };
  }

  get memberPrice() {
    return this.subscriptionManager.options.additionalUser?.value;
  }

  get teams() {
    return this.organizationManager.organization.teams;
  }

  get hasReachedUserLimit() {
    return this.subscriptionManager.hasReachedUserLimit;
  }

  get isOverUserLimit() {
    let { membershipsCountingTowardsPlanLimitCount } = this.organizationManager.organization;
    let userLimit = this.subscriptionManager.getLimitByFeatureCode('additional_users');

    return userLimit !== null && membershipsCountingTowardsPlanLimitCount - 1 <= userLimit;
  }

  get canReadCustomPermissions() {
    return this.abilities.can('read role') && this.args.member?.last?.value?.customPermissions;
  }

  get isManager() {
    return this.organizationManager.membership.role === ROLES.MANAGER;
  }

  get isMemberTeamManager() {
    return this.args.member?.last?.value?.customPermissions?.scope === 'team';
  }

  get isMemberOrganizationManager() {
    return this.args.member?.last?.value?.customPermissions?.scope === 'organization';
  }

  get canUpdateMember() {
    let model = this.args.member?.last?.value;

    if (this.isManager) {
      return model?.role === ROLES.EMPLOYEE;
    }

    return (
      model?.role === ROLES.OWNER ||
      model?.role === ROLES.ADMIN ||
      model?.role === ROLES.REPORTING ||
      model?.role === ROLES.MANAGER ||
      model?.role === ROLES.EMPLOYEE
    );
  }

  /**
   * Checks if the logged user can see the monthly transfer limit property.
   */
  get showMonthlyTransferLimit() {
    return this.showLimit(this.args.member?.last?.value?.monthlyTransferLimit);
  }

  /**
   * Checks if the logged user can see the per transfer limit property.
   */
  get showPerTransferLimit() {
    return this.showLimit(this.args.member?.last?.value?.perTransferLimit);
  }

  get cardsCount() {
    let cardsCountPerMembership = this.cardsManager.counters?.per_membership_all;
    return cardsCountPerMembership?.find(
      ({ membership_id }) => membership_id === this.args.member?.last?.value?.id
    )?.cards_count;
  }

  get activeCardsCount() {
    let cardsCountPerMembership = this.cardsManager.counters?.per_membership_active;
    return cardsCountPerMembership?.find(
      ({ membership_id }) => membership_id === this.args.member?.last?.value?.id
    )?.cards_count;
  }

  /**
   * Verify if the logged user can see the spend limits properties.
   * @param {Object} limit the limit to verify, could be null if not setted
   * @returns true if the logged user can see the spend limits properties.
   */
  showLimit(limit) {
    let currentUserId = this.organizationManager.membership.id;
    let managerCondition = this.isManager && currentUserId === this.args.member?.last?.value?.id;

    let adminCondition = !this.isManager && this.abilities.can('view transfer-limit');
    let createReviewTransferPermission =
      this.expensePermissions.create_transfers_and_review_request_transfers;
    return createReviewTransferPermission && (adminCondition || (managerCondition && limit));
  }

  @action
  toggleEditingTeam() {
    if (!this.editingTeam) {
      this.changeTeam(this.args.member?.last?.value?.team);
    }
    this.editingTeam = !this.editingTeam;
  }

  @action
  changeTeam(team) {
    this.team = team;
  }

  sendInviteAgainTask = dropTask(async membership => {
    try {
      await membership.renewInvite();

      let message = this.intl.t('toasts.membership_invite_sent_again');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  saveTeamTask = dropTask(async membership => {
    try {
      this.toggleEditingTeam();

      if (this.team.id !== membership.team.get('id')) {
        membership.team = this.team;

        await membership.save();

        this.segment.track('member_team_update_confirmed', {
          flow: 'update-team',
        });

        let message = this.intl.t('toasts.membership_team_updated');
        this.toastFlashMessages.toastSuccess(message);
      }
    } catch {
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
    }
  });

  revokeTask = dropTask(async close => {
    let membership = this.args.member?.last?.value;

    await membership.revoke();
    await this.organizationManager.organization.reload();
    this.segment.track('member_disable_confirmed');

    let message = this.intl.t('toasts.members.disabled-success');
    this.toastFlashMessages.toastInfo(message);

    close();

    this.router.replaceWith('members.active', this.organizationManager.organization.slug, {
      queryParams: { page: 1 },
    });
  });

  unRevokeTask = dropTask(async membership => {
    await membership.unrevoke();
    await this.organizationManager.organization.reload();

    let message = this.intl.t('toasts.members.invitation-success');
    this.toastFlashMessages.toastInfo(message);
  });

  cancelInvitationTask = dropTask(async close => {
    await this.args.member.last.value
      .revoke()
      .then(async () => {
        await this.organizationManager.organization.reload();

        this.segment.track('member_cancel_invite_confirmed');

        this.toastFlashMessages.toastInfo(this.intl.t('toasts.cancel-invitation-success'));

        close();

        this.router.transitionTo('members.active', this.organizationManager.organization.slug, {
          queryParams: { page: 1 },
        });
      })
      .catch(ignoreCancelation);
  });

  @action
  openCancelInviteModal() {
    this.modals.open('popup/destructive', {
      title: this.intl.t('members.cancel-modal.title'),
      confirm: this.intl.t('members.cancel-modal.confirm-cta'),
      cancel: this.intl.t('btn.back'),
      description: this.intl.t('members.cancel-modal.subtitle'),
      confirmTask: this.cancelInvitationTask,
    });
  }

  @action
  handleClick() {
    this.segment.track('member_team_update_started', { flow: 'update-team' });
    this.toggleEditingTeam();
  }

  openRevokeAccessModalTask = task(async () => {
    let { memberPrice } = this;
    let isAcountantAccess =
      this.abilities.can('access accountant-access') && this.args.member.reporting;
    let isPaidMember =
      !isAcountantAccess &&
      this.abilities.can('create paid members members') &&
      this.isOverUserLimit;

    let isRefundable = isPaidMember;

    let isDiscountActive;
    if (this.abilities.can('read discount')) {
      let discount;

      try {
        discount = await this.freeUsersDiscountManager.fetchDiscountTask.perform({
          includeInvalid: true,
          includeFinishedThisMonth: true,
        });
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
      isDiscountActive = this.freeUsersDiscountManager.getIsDiscountActive(discount);
    }

    if (isDiscountActive) {
      isRefundable = false;
    }

    this.modals.open('member/modal/confirm-revoke-member', {
      confirmTask: this.revokeTask,
      memberPrice,
      isRefundable,
    });
  });

  @action handleViewCards() {
    let fullName = this.args.member?.last?.value?.fullName;
    let targetRoute = 'cards.team';
    this.router.transitionTo(targetRoute, this.organizationManager.organization.slug, {
      queryParams: {
        query: fullName,
      },
    });
    this.segment.track('team_members_page_cards_selected');
  }

  @action handleViewTransactions() {
    this.router.transitionTo('transactions.index', this.organizationManager.organization.slug, {
      queryParams: {
        initiator: this.args.member?.last?.value?.id,
      },
    });
  }

  @action
  handleReactivateMemberClick() {
    this.unRevokedMemberConfirmTask.perform().catch(ignoreCancelation);
    this.segment.track('member_re_enable', { role: this.args.member?.last?.value?.role });
  }

  @action
  handleResendInvitationClick() {
    this.sendInviteAgainTask.perform(this.args.member?.last?.value).catch(ignoreCancelation);
    this.segment.track('member_resend_invite', { role: this.args.member?.last?.value?.role });
  }

  @action
  handleCancelInvitationClick() {
    this.openCancelInviteModal();
    this.segment.track('member_cancel_invite_started');
  }

  @action
  handleRevokeMembeshipClick() {
    this.openRevokeAccessModalTask.perform().catch(ignoreCancelation);
    this.segment.track('member_disable_started', {
      membershipStatus: this.args.member?.last?.value?.status,
      role: this.args.member?.last?.value?.role,
    });
  }

  unRevokedMemberConfirmTask = dropTask(async () => {
    let { memberPrice } = this;

    let membership = this.args.member?.last?.value;
    let isAcountantAccess = this.abilities.can('access accountant-access') && membership.reporting;
    let isLegacyPlan = !this.abilities.can('create paid members members');

    let eligibilityCriteria;
    let isPaidMember = false;
    if (!this.shouldShowTeamPricingExperiment) {
      let discount;
      try {
        discount = await this.freeUsersDiscountManager.fetchDiscountTask.perform({
          includeInvalid: true,
        });
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }

      eligibilityCriteria = this.freeUsersDiscountManager.getEligibilityCriteria(discount);
      isPaidMember = !isAcountantAccess && this.hasReachedUserLimit;
    }

    if (isLegacyPlan && isPaidMember) {
      this.modals.open('upgrade-required-modal', { confirmRedirect: this.handleUpgradeRedirect });
    } else {
      let hasPlanWithHigherUserLimit = isPaidMember
        ? await this.hasPlanWithHigherUserLimit()
        : false;
      let isUpgradePlanLinkVisible =
        hasPlanWithHigherUserLimit && this.abilities.can('view subscription');

      let shouldBeCharged = isPaidMember;

      if (eligibilityCriteria) {
        shouldBeCharged = !eligibilityCriteria.canAddFreeUsers;
      }

      await this.modals.open('member/modal/confirm-unrevoke-member', {
        shouldBeCharged,
        membership,
        memberPrice,
        pricePlanCode: this.subscriptionManager.currentPricePlan.code,
        isUpgradePlanLinkVisible,
        confirmTask: this.unRevokeTask,
      });
    }
  });

  @action
  handleUpgradeRedirect(close) {
    this.flowLinkManager.transitionTo({ name: 'subscription-change', stepId: 'plans' });
    close();
  }

  async hasPlanWithHigherUserLimit() {
    try {
      return await this.subscriptionManager.hasUpgrade('users');
    } catch (error) {
      this.errors.handleError(error);
      return false;
    }
  }

  handleDestroyMember = dropTask(async member => {
    this.segment.track('user-access_hide-cta_clicked', {
      origin: 'to-invite',
    });

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('members.list.modals.hide-suggestion.title'),
      confirm: this.intl.t('btn.remove-team-member'),
      cancel: this.intl.t('btn.cancel'),
      description: this.intl.t('members.list.modals.hide-suggestion.subtitle'),
    });

    if (result === 'confirm') {
      this.segment.track('user-access_confirm-hide-cta_clicked');
      try {
        await member.destroyRecord();
        this.toastFlashMessages.toastInfo(
          this.intl.t('members.list.toasts.hide-suggestion.success')
        );
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      }
      this.router.replaceWith('members.to-invite', this.organizationManager.organization.slug);
    }
  });

  get integration() {
    return JSON.parse(
      safeLocalStorage.getItem(`integration_${this.args.member?.last?.value?.provider}`)
    );
  }

  get providerName() {
    let integration = this.integration;
    return integration.name;
  }

  get providerLogo() {
    let integration = this.integration;
    return integration.logo;
  }

  get membershipName() {
    return this.args.member?.last?.value?.fullName ?? this.args.member?.last?.value?.email;
  }

  get allowedBankAccounts() {
    // If the logged user is the manager of the organization, the allowed bank accounts are the
    // bank accounts of the organization.
    if (
      this.isManager &&
      this.args.member?.last?.value?.id === this.organizationManager.membership.id
    ) {
      return this.store.peekAll('bank-account');
    }
    return this.args.member.last.value.allowedBankAccounts;
  }

  get remainingAllowedBankAccountsLength() {
    return this.allowedBankAccounts.length - this.allowedBankAccountsPreviewLength;
  }

  get previewAllowedBankAccountsNames() {
    return this.allowedBankAccounts
      .slice(0, this.allowedBankAccountsPreviewLength)
      .map(acc => acc.name)
      .join(', ');
  }

  get allowedBankAccountsNamesTooltipMessage() {
    let names = this.allowedBankAccounts.map(acc => acc.name);
    if (names.length > this.allowedBankAccountsMaxLength) {
      let displayedNames = names.slice(0, this.allowedBankAccountsMaxLength).join(', ');
      let remainingCount = names.length - this.allowedBankAccountsMaxLength;
      let moreText = this.intl.t('text.more', { count: remainingCount });
      return `${displayedNames} ${moreText}`;
    } else {
      return names.join(', ');
    }
  }
}
