export default {
  "wrapper": "nl",
  "subtitle": "nX body-2",
  "legend": "nC",
  "table": "nk",
  "footer": "nJ",
  "footer-total-c": "ns",
  "footer-total-d": "nF",
  "footer-balance": "ng"
};
