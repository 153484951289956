export default {
  "container": "AW",
  "header-wrapper": "Au mb-16",
  "header": "YS",
  "logo": "Yc mr-16",
  "title": "Yq title-4",
  "description": "YZ body-2",
  "conditions": "YR",
  "label": "YQ caption mb-4",
  "tags": "Ye"
};
