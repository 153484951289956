export default {
  "wrapper": "nO",
  "legend": "nm",
  "input": "np",
  "table": "nx",
  "footer": "nw",
  "footer-total-m": "nN",
  "footer-total-n": "nv",
  "footer-balance": "no"
};
