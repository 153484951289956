export default {
  "header": "ckC",
  "center": "ckk",
  "header-placeholder": "ckJ",
  "placeholder": "cks",
  "name-block": "ckF",
  "block": "ckg",
  "detail-block": "ckD",
  "border-top": "ckV"
};
