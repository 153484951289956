export default {
  "wrapper": "ow",
  "legend": "oN",
  "grid": "ov",
  "subtitle": "oo body-2",
  "fiscal-code": "on",
  "personal-data-name": "oi",
  "personal-data-birth": "oA",
  "personal-data": "oY",
  "tax-residence": "oh",
  "payer-agent": "of",
  "errors": "oK",
  "business": "oG",
  "address": "or"
};
