export default {
  "step-container": "WX",
  "content": "WC",
  "section": "Wk",
  "section-content": "WJ",
  "section-content-text": "Ws",
  "section-illustration": "WF",
  "close-form-cta": "Wg",
  "bank-account-select": "WD"
};
