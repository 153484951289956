export default {
  "card": "rB",
  "asset": "rE",
  "container": "rd",
  "subtitle": "rI",
  "header": "rt",
  "header-name": "rM",
  "option-list": "rP",
  "feature": "rl",
  "highlighted": "rX"
};
