export default {
  "invitations-password-form": "cCM",
  "mobile-mode": "cCP",
  "logo": "cCl",
  "header": "cCX",
  "controls": "cCC",
  "footer": "cCk",
  "submit-cta": "cCJ",
  "subtitle": "cCs"
};
