export default {
  "member-details": "csN",
  "header": "csv",
  "no-email": "cso",
  "header-img": "csn",
  "invited-persona": "csi",
  "revoked-persona": "csA",
  "invited-icon": "csY",
  "revoked-icon": "csh",
  "revised": "csf",
  "invitable-icon": "csK",
  "dropdown": "csG",
  "header-body": "csr",
  "status": "csb",
  "name": "csy title-3",
  "email": "csU caption-bold",
  "actions": "csj body-2",
  "actions-cards": "csW",
  "actions-transactions": "csu",
  "body": "cFS",
  "expense-permissions-section": "cFc",
  "body-title": "cFq title-4",
  "body-attr": "cFZ",
  "body-label": "cFR",
  "attr-buttons": "cFQ",
  "body-role": "cFe small",
  "details-actions": "cFB",
  "actions-item": "cFE",
  "disclaimer": "cFd",
  "permissions": "cFI",
  "permission": "cFt",
  "permission-label": "cFM",
  "permission-check": "cFP",
  "permission-missing": "cFl",
  "editable-section": "cFX",
  "hris-placeholder": "cFC",
  "imported-from": "cFk",
  "integration-logo": "cFJ",
  "permission-value": "cFs",
  "tooltip": "cFF",
  "check-icon": "cFg"
};
