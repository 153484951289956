export default {
  "mandate-list-item": "csd",
  "selected": "csI",
  "status-avatar": "cst",
  "infos": "csM",
  "info-title": "csP body-1",
  "info-title-suspended": "csl",
  "info-title-status-suspended": "csX",
  "info-mandate-count": "csC body-2"
};
