export default {
  "label-wrapper": "yA body-1",
  "avatar": "yY",
  "container": "yh",
  "transaction-info": "yf",
  "secondary": "yK",
  "amount-container": "yG",
  "amount": "yr",
  "disabled": "yb",
  "checkbox": "yy"
};
