/* import __COLOCATED_TEMPLATE__ from './company-details.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsAccountReceivableOnboardingCompanyDetails extends Component {
  @action
  handleConfirm() {
    if (this.args.context.isFrenchOrganization) {
      this.args.transitionToNext();
    } else {
      this.args.backToStep('summary');
    }
  }
}
