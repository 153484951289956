export default {
  "bucket-item": "cMO",
  "button": "cMm",
  "content": "cMp",
  "bucket-icon": "cMx",
  "bucket-title-container": "cMw",
  "bucket-new-badge": "cMN",
  "bucket-title": "cMv",
  "arrow-icon": "cMo"
};
