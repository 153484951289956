export default {
  "container": "bB",
  "centered": "bE",
  "content": "bd",
  "spinner": "bI",
  "holder-selector": "bt",
  "holder-selector-error": "bM",
  "info-text": "bP",
  "error-text": "bl",
  "lottie-illustration": "bX",
  "info-text-1": "bC",
  "info-text-2": "bk"
};
