export default {
  "wrapper": "cdN input-field mb-4",
  "error": "cdv",
  "inactive": "cdo",
  "hidden-value": "cdn",
  "percentage-sign": "cdi",
  "black": "cdA",
  "input-field": "cdY",
  "disabled": "cdh"
};
