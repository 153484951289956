export default {
  "checks": "UF",
  "wrapper": "Ug",
  "form": "UD",
  "title": "UV",
  "wrapper-body": "UL",
  "aside": "UT",
  "steps-container": "Uz",
  "step": "Ua",
  "container-dot": "UH",
  "text": "UO",
  "text-title": "Um",
  "text-desc": "Up",
  "start-button": "Ux"
};
