export default {
  "step-container": "jK",
  "content": "jG",
  "detail": "jr",
  "secondary": "jb",
  "reference": "jy",
  "fees-label": "jU",
  "fees-tooltip": "jj",
  "separator": "jW",
  "mt-32": "ju"
};
