/* import __COLOCATED_TEMPLATE__ from './easter-egg.hbs'; */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from '@repo/design-system-kit';

export default class EasterEggComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  @tracked animationReady = false;

  constructor() {
    super(...arguments);

    this.segment.track('easter_egg_found');
  }
}
