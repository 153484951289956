export default {
  "checks": "UZ",
  "content": "UR",
  "form": "UQ",
  "title": "Ue",
  "description": "UB",
  "wrapper": "UE",
  "aside": "Ud",
  "amount-field": "UI"
};
