/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import CURRENCIES from 'qonto/constants/currencies';
import { PAY_LATER_SIGNATURE_STATUS } from 'qonto/constants/financing';
import { ROLES } from 'qonto/constants/membership';
import {
  DISCLAIMER_TYPES,
  INSTANT_DISCLAIMER_TYPES,
  SPEND_LIMIT_TYPES,
} from 'qonto/constants/transfers';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { prepareLimitsData } from 'qonto/utils/transfers';

export default class FlowsTransfersSepaNewSummaryComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  disclaimerBlock = Disclaimer.Block;

  @service abilities;
  @service featuresManager;
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service store;
  @service organizationManager;

  @tracked isRequestValidationEnabled = false;
  @tracked isPayLater = false;
  @service sensitiveActions;

  constructor() {
    super(...arguments);

    let { sepaTransfer, signature, confirmationResult, origin } = this.args.context;

    this.segment.track('transfer-sepa_summary_displayed', {
      'sepa-warnings': confirmationResult.warnings,
      ...(origin && { origin }),
    });

    this.isPayLater = sepaTransfer.isPayLater;

    sepaTransfer.instant = this.isInstantAvailable;

    if (signature?.status === PAY_LATER_SIGNATURE_STATUS.SUCCESS) {
      this.args.context.isPayLaterContractSigned = true;
      this.sensitiveActions.runTask.perform(this.saveTransferTask).catch(ignoreCancelation);
    }
  }

  @action
  togglePayLater() {
    let { instantPayLaterAvailable } = this.args.context.confirmationResult;

    if (!this.isPayLater && !instantPayLaterAvailable) {
      this.args.context.sepaTransfer.instant = false;
    }

    this.isPayLater = !this.isPayLater;

    this.args.context.sepaTransfer.operationType = this.isPayLater ? 'pay_later' : 'scheduled';
    this.args.context.sepaTransfer.financingInstallments = 3;

    this.segment.track('pay_later-toggle_switch', { state: this.isPayLater ? 'on' : 'off' });
  }

  get isInstantPayLaterDisabled() {
    let { instantPayLaterAvailable } = this.args.context.confirmationResult;

    return this.isPayLater && !instantPayLaterAvailable;
  }

  get isPayLaterUnavailable() {
    let { didValidateFinancing, didInstallmentsFail } = this.args.context;
    return !didValidateFinancing || didInstallmentsFail;
  }

  get aboveLimitType() {
    return this.transferLimitsData.aboveLimitType;
  }

  get beneficiary() {
    return this.sepaTransfer.get('beneficiary');
  }

  get canSeeBalance() {
    return this.abilities.can('see balance bankAccount');
  }

  get disclaimers() {
    let { warnings, errors, fees, role } = this.args.context.confirmationResult || {};
    return this._getSepaDisclaimers({ warnings, errors, fees, role });
  }

  get generalDisclaimers() {
    let filteredTypes = Object.values(INSTANT_DISCLAIMER_TYPES);

    return this.disclaimers.filter(disclaimer => {
      if (disclaimer.type === DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT) {
        return false;
      }
      return !filteredTypes.includes(disclaimer.type);
    });
  }

  get hasConfirmationErrors() {
    return this.args.context.confirmationResult?.errors?.length > 0;
  }

  get instantDisclaimers() {
    return this.disclaimers.filter(disclaimer =>
      Object.values(INSTANT_DISCLAIMER_TYPES).includes(disclaimer.type)
    );
  }

  get isAboveTransferLimits() {
    return Boolean(this.aboveLimitType);
  }

  get isInstantAvailable() {
    let { confirmationResult, isInstantFallback } = this.args.context;

    if (isInstantFallback) return false;

    let { instantAvailable, instantPayLaterAvailable } = confirmationResult || {};

    let isInstantAvailable = this.isPayLater ? instantPayLaterAvailable : instantAvailable;

    return (
      this.featuresManager.flags.sepaInstantOut && isInstantAvailable && !this.hasConfirmationErrors
    );
  }

  get mainAccountName() {
    return this.args.context.sepaTransfer.organization.get('mainAccount').name;
  }

  get isInstantToggleDisabled() {
    return !isEmpty(this.instantDisclaimers) || !this.isInstantAvailable;
  }

  get isBookTransfer() {
    return this.disclaimers.some(el => el.type === DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT);
  }

  get isInstantVisible() {
    return (
      this.isInstantAvailable ||
      (!isEmpty(this.instantDisclaimers) && !this.isBookTransfer) ||
      this.args.context.isInstantFallback
    );
  }

  get organization() {
    return this.sepaTransfer.get('organization');
  }

  get sepaTransfer() {
    return this.args.context.sepaTransfer;
  }

  get transferDate() {
    let { scheduledDate, instant } = this.sepaTransfer;
    return instant
      ? this.intl.t('transfers.modals.confirm_transfer.instant.date')
      : dayjs(scheduledDate).format('DD/MM/YYYY');
  }

  get transferLimitExceededDisclaimerMessage() {
    let { perTransfer, monthly, monthSpendings } = this.transferLimitsData;

    if (this.aboveLimitType === SPEND_LIMIT_TYPES.PER_TRANSFER) {
      return this.intl.t('requests.transfers.modals.confirm_request.disclaimers.per-transfer', {
        per_transfer_limit: perTransfer,
      });
    }

    if (this.aboveLimitType === SPEND_LIMIT_TYPES.MONTHLY) {
      return this.intl.t('requests.transfers.modals.confirm_request.disclaimers.monthly-standard', {
        balance_monthly_transfer_limit: this.intl.formatNumber(monthly - monthSpendings, {
          currency: CURRENCIES.default,
          minimumFractionDigits: 0,
          style: 'currency',
        }),
      });
    }

    if (this.aboveLimitType === SPEND_LIMIT_TYPES.RECURRING) {
      return this.intl.t(
        'requests.transfers.modals.confirm_request.disclaimers.monthly-per-transfer-recurring'
      );
    }
  }

  get showPayLaterToggle() {
    let { invoice, isPayLaterEligible } = this.args.context;

    return (
      this.abilities.can('view pay later toggle in financing') && invoice?.id && isPayLaterEligible
    );
  }

  get transferLimitsData() {
    let { spendLimits, warnings } = this.args.context.confirmationResult;
    return prepareLimitsData(warnings, spendLimits);
  }

  get transferRequest() {
    return this.args.context.transferRequest;
  }

  get instantTooltipMessage() {
    return this.instantDisclaimers.map(({ message }) => message).join(' ');
  }

  get organizationLegalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get financingDetails() {
    let { payLaterInstallments, sepaTransfer } = this.args.context;

    let selectedInstallment = payLaterInstallments.options.find(
      option => option.numberOfInstallments === sepaTransfer.financingInstallments
    );

    return {
      totalAmount: selectedInstallment.totalAmount,
      totalFee: selectedInstallment.totalFees,
    };
  }

  @action
  toggleInstant() {
    this.sepaTransfer.instant = !this.sepaTransfer.instant;
  }

  @action
  handleInstallmentOptionChange(option) {
    this.args.context.sepaTransfer.financingInstallments = option;
  }

  @action
  handleConfirm() {
    if (this.isPayLater && !this.args.context.isPayLaterContractSigned) {
      this.args.transitionToNext();
    } else {
      this.sensitiveActions.runTask.perform(this.saveTransferTask).catch(ignoreCancelation);
    }
  }

  saveTransferRequestTask = dropTask(async () => {
    this.isRequestValidationEnabled = false;

    await this.transferRequest.validate();

    let isRequestValid = this.transferRequest.validations.isValid;

    if (!isRequestValid) {
      this.isRequestValidationEnabled = true;
      return;
    }

    let { context, transitionToNext } = this.args;

    try {
      let response = await this.transferRequest.save();

      context.transferRequest = this.store.peekRecord('request-transfer', response.id);

      let savedAttachements = this.transferRequest
        .get('attachments')
        .filter(item => get(item, 'isNew'));
      savedAttachements.forEach(attachment => attachment.unloadRecord());

      this.segment.track('request_admin_approval_clicked');

      transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  saveTransferTask = dropTask(async () => {
    this.sepaTransfer.addIdempotencyHeader();

    let { context, transitionToNext } = this.args;

    try {
      let savedSepaTransfer = await this.sepaTransfer.save();

      context.sepaTransfer = this.store.peekRecord('transfer', savedSepaTransfer.id);

      let savedAttachements = this.sepaTransfer
        .get('attachments')
        .filter(item => get(item, 'isNew'));

      savedAttachements.forEach(attachment => attachment.unloadRecord());

      this._trackTransferCreation();

      transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      this.sepaTransfer.removeIdempotencyHeader();
    }
  });

  _getSepaDisclaimers({ warnings = [], errors = [], fees = {}, role }) {
    let disclaimerTypes = [
      ...warnings,
      ...errors,
      ...(fees && fees.amount > 0 ? [DISCLAIMER_TYPES.COST] : []),
      ...(this.isInstantPayLaterDisabled
        ? [DISCLAIMER_TYPES.INSTANT_NOT_AVAILABLE_WITH_PAY_LATER]
        : []),
    ];

    let disclaimers = this._getSepaDisclaimersList({ fees, role });

    let selectDisclaimer = disclaimer => disclaimerTypes.find(type => type === disclaimer.type);
    return disclaimers.filter(selectDisclaimer);
  }

  get shoudDisplayKycDisclaimer() {
    let { kycAccepted } = this.organizationManager.membership;

    return !kycAccepted;
  }

  get kycPendingDisclaimer() {
    return {
      level: 'warning',
      message: this.intl.t('transfers.warnings.kyc-waiting'),
    };
  }

  get bookTransferDisclaimer() {
    return {
      type: DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT,
      level: 'info',
      message: this.intl.t('transfers.warnings.qonto_bank_account'),
    };
  }

  _getSepaDisclaimersList({ fees, role }) {
    return [
      this.bookTransferDisclaimer,
      {
        type: DISCLAIMER_TYPES.ALREADY_ISSUED,
        level: 'warning',
        message: this.intl.t('transfers.warnings.already_issued'),
      },
      {
        type: DISCLAIMER_TYPES.BILLER_INSUFFICIENT_FUNDS,
        level: 'error',
        message:
          role === ROLES.MANAGER
            ? this.intl.t('transfers.warnings.manager.insufficient_funds')
            : this.intl.t('transfers.warnings.insufficient_funds'),
      },
      {
        type: DISCLAIMER_TYPES.COST,
        level: 'warning',
        message: this.intl.t('transfers.warnings.cost', {
          computed_amount: this.intl.formatMoney(fees.amount, {
            currency: fees.amount_currency,
          }),
        }),
      },
      {
        type: DISCLAIMER_TYPES.KYB_WAITING,
        level: 'warning',
        message: this.intl.t('transfers.warnings.kyb_waiting'),
      },
      {
        type: DISCLAIMER_TYPES.TOP_UP_REQUIRED,
        level: 'warning',
        message: this.intl.t('transfers.warnings.top_up_required'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_NON_REACHABLE_BIC,
        level: 'info',
        message: this.intl.t('transfers.warnings.instant-non-reachable-bic'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_UNDER_MAINTENANCE,
        level: 'warning',
        message: this.intl.t('transfers.warnings.instant-under-maintenance'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_AMOUNT_ABOVE_THRESHOLD,
        level: 'info',
        message: this.intl.t('transfers.warnings.instant-amount-above-threshold'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_OPERATIONAL_UNAVAILABILITY,
        level: 'info',
        message: this.intl.t('transfers.warnings.instant-operational-unavailability'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_FUNCTIONAL_UNAVAILABILITY,
        level: 'info',
        message: this.intl.t('transfers.warnings.instant-functional-unavailability'),
      },
      {
        type: DISCLAIMER_TYPES.INSTANT_NOT_AVAILABLE_WITH_PAY_LATER,
        level: 'info',
        message: this.intl.t('transfers.warnings.pay-later.instant-unavailable'),
      },
    ];
  }

  _trackTransferCreation() {
    let { invoice, isInstantFallback, origin } = this.args.context;

    if (isInstantFallback) {
      this.segment.track('transfer_create_submitted', {
        is_instant_resent_as_regular: true,
      });
    } else {
      let { attachments, labels, note, notifyByEmail, email } = this.sepaTransfer;

      let hasAttachments = Boolean(attachments.length);
      let hasLabels = Boolean(labels.length);
      let hasNote = Boolean(note);
      let hasEmail = Boolean(notifyByEmail && email);

      this.segment.track('transfer-sepa_summary_confirmed', {
        ...(hasAttachments && { attachments: hasAttachments }),
        ...(hasLabels && { labels: hasLabels }),
        ...(hasNote && { note: hasNote }),
        ...(hasEmail && { email: hasEmail }),
        ...(invoice?.id && { flow: 'byinvoice' }),
        ...(origin && { origin }),
      });
    }
  }
}
