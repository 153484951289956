export default {
  "animated": "Gx",
  "container": "Gw",
  "cards-selectors": "GN",
  "card-selector": "Gv",
  "selector": "Go",
  "header": "Gn",
  "footer": "Gi",
  "skip-cta": "GA",
  "pricing-caption": "GY",
  "show": "Gh",
  "selectorFadeIn": "Gf",
  "fadeIn": "GK",
  "asset-one": "GG",
  "onePlusAssetFadeIn": "Gr",
  "assetMoveUp": "Gb",
  "asset-plus": "Gy",
  "asset-x": "GU",
  "xAssetFadein": "Gj",
  "feature": "GW",
  "highlighted": "Gu"
};
