export default {
  "container": "clh",
  "header": "clf",
  "content": "clK",
  "info-list": "clG",
  "info-label": "clr body-2",
  "info-value": "clb body-2",
  "provider": "cly",
  "policy-container": "clU",
  "policy-label": "clj body-2",
  "policy-number": "clW body-2",
  "copy-to-clipboard": "clu",
  "button": "cXS btn btn--primary btn--stretch btn--large"
};
