export default {
  "container": "cBv",
  "content": "cBo",
  "sticky": "cBn",
  "divider": "cBi",
  "tooltip": "cBA",
  "detail": "cBY",
  "detail-value": "cBh",
  "color-secondary": "cBf",
  "beneficiary-information": "cBK",
  "beneficiary-source": "cBG",
  "currency": "cBr",
  "break-word": "cBb",
  "disclaimers": "cBy",
  "sticky-panel": "cBU"
};
