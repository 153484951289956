/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';

export default class InContextIntegrationsSideDrawerIntegrationListComponent extends Component {
  @service segment;

  @tracked searchedValue = this.args.query || '';
  @tracked scrolledTop = true;

  updateIntegrationListTask = restartableTask(async value => {
    this.searchedValue = value;

    await timeout(DEBOUNCE_MS);
    this.args.updateSearchValue(value);
    this.segment.track('ICI_search_clicked', { page: this.args.page });
  });

  @action
  updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}
