export default {
  "body": "cFY",
  "members": "cFh",
  "members-results": "cFf",
  "members-list": "cFK",
  "members-title": "cFG caption-bold",
  "member": "cFr",
  "empty-illustration": "cFb",
  "empty-title": "cFy title-3",
  "empty-description": "cFU body-2",
  "invitable-members": "cFj",
  "details": "cFW",
  "pagination-footer": "cFu"
};
