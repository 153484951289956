/* import __COLOCATED_TEMPLATE__ from './customization-brand-color.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsAccountReceivableOnboardingCustomizationBrandColor extends Component {
  @action
  handleConfirm() {
    if (this.args.context.isGermanOrganization) {
      this.args.transitionToNext();
    } else {
      this.args.backToStep('summary');
    }
  }
}
