export default {
  "mandates": "vv",
  "mandates-list": "vo",
  "left-section": "vn",
  "header-search": "vi",
  "header": "vA",
  "header-content": "vY",
  "search": "vh",
  "search-bar": "vf",
  "body": "vK",
  "list-title": "vG caption-bold",
  "details": "vr",
  "avatar": "vb mr-16",
  "header-beneficiary": "vy",
  "header-beneficiary-activity": "vU",
  "header-beneficiary-details": "vj",
  "header-beneficiary-details-recurring": "vW",
  "infos": "vu",
  "infos-title": "oS",
  "infos-empty": "oc",
  "infos-list": "oq",
  "infos-list-item": "oZ",
  "infos-list-item-left": "oR",
  "infos-list-item-right": "oQ",
  "infos-list-item-attachment": "oe"
};
