export default {
  "container": "KR",
  "container-content": "KQ",
  "content": "Ke",
  "underline": "KB",
  "inline-content": "KE",
  "payment-rate": "Kd",
  "payment-subtitle": "KI",
  "grey-text": "Kt",
  "earnings-header": "KM",
  "earnings-tooltip": "KP"
};
