export default {
  "header-cell": "ckf",
  "empty": "ckK",
  "header-content": "ckG caption-bold",
  "active": "ckr",
  "align-right": "ckb",
  "first-col": "cky",
  "mid-col": "ckU",
  "status-col": "ckj"
};
