export default {
  "wrapper": "ob",
  "subtitle": "oy body-2",
  "legend": "oU",
  "table": "oj",
  "footer": "oW",
  "footer-codice-1": "ou",
  "footer-codice-2": "nS",
  "footer-total-a": "nc",
  "footer-total-b": "nq",
  "footer-balance": "nZ",
  "errors": "nR"
};
