export default {
  "journey": "cZC",
  "content": "cZk",
  "distance": "cZJ",
  "google-link-container": "cZs",
  "link-icon": "cZF",
  "continue-btn": "cZg",
  "origin": "cZD",
  "input-width": "cZV"
};
