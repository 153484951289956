/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask, waitForProperty } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class FlowsTransfersFxNewDetailsComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;
  @service toastFlashMessages;
  @service segment;
  @service store;
  @service router;

  @tracked enableValidation = false;

  constructor() {
    super(...arguments);

    let beneficiaryId = this.fxTransfer.get('beneficiary.id');

    if (!beneficiaryId) {
      this.router.replaceWith('transfers.landing');
    } else {
      this.getTransferRequirementsTask
        .perform(beneficiaryId)
        .then(({ minFxAmount, paymentPurposeCodes }) => {
          // for state restore
          this.args.context.paymentPurposeCodes = paymentPurposeCodes;
          // for validation
          this.fxTransfer.minFxAmount = minFxAmount;
        })
        .catch(ignoreCancelation);
    }
  }

  get fxTransfer() {
    return this.args.context.fxTransfer;
  }

  get beneficiary() {
    return this.fxTransfer.get('beneficiary');
  }

  get organization() {
    return this.fxTransfer.get('organization');
  }

  get amountNumberOfDecimal() {
    return this.fxTransfer.localAmountCurrency === 'JPY' ? 0 : 2;
  }

  get amountPlaceholder() {
    return this.fxTransfer.localAmountCurrency === 'JPY' ? '0' : '0.00';
  }

  get amountStep() {
    return this.fxTransfer.localAmountCurrency === 'JPY' ? '1' : '0.01';
  }

  getTransferRequirementsTask = dropTask(async beneficiaryId => {
    let { requirements } = await this.store
      .adapterFor('transfer')
      .getTransferRequirements(beneficiaryId);

    return {
      minFxAmount: requirements?.minimum_amount?.value,
      paymentPurposeCodes: requirements?.payment_purpose,
    };
  });

  onClickNextTask = dropTask(async fxTransfer => {
    await waitForProperty(this.getTransferRequirementsTask, 'isIdle');

    await fxTransfer.validate();

    this.enableValidation = true;

    let { reference, bankAccount, localAmount } = fxTransfer.validations.attrs;
    if (reference.isInvalid || bankAccount.isInvalid || localAmount.isInvalid) {
      this._scrollToErrorField();
      return;
    }

    try {
      let data = {
        currency: fxTransfer.localAmountCurrency,
        amount: fxTransfer.localAmount,
        organization_id: fxTransfer.get('organization.id'),
      };

      let { opened: isMarketOpened, reason } = await this.store
        .adapterFor('transfer')
        .checkFxMarketIsOpen(data);

      if (isMarketOpened) {
        let { origin } = this.args.context;

        this.segment.track('transfer-swift_amount_submitted', {
          ...(origin && { origin }),
        });
      } else {
        this.args.context.isMarketClosed = true;
        this.args.context.closedMarketReason = reason.code;
      }

      this.args.transitionToNext();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }
}
