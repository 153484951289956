export default {
  "selector": "rz",
  "metal-design": "ra",
  "content": "rH",
  "header": "rO",
  "big": "rm",
  "header-name": "rp",
  "subtitle": "rx",
  "button": "rw",
  "features": "rN",
  "feature": "rv",
  "highlighted": "ro"
};
