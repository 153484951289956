/* import __COLOCATED_TEMPLATE__ from './pricing.hbs'; */
import Component from '@glimmer/component';

export default class CardStepsPricingOnboarding extends Component {
  get cardEstimates() {
    let { cardLevel, estimates } = this.args;
    return estimates?.[cardLevel];
  }

  get monthlyCost() {
    return this.cardEstimates?.monthly_cost;
  }

  get discountedMonthlyCost() {
    return this.cardEstimates.discounted_monthly_cost;
  }

  get discountFinishDate() {
    return this.cardEstimates.discount_finish_date;
  }

  get hasDiscount() {
    return (
      this.cardEstimates.discount_finish_date && this.monthlyCost !== this.discountedMonthlyCost
    );
  }

  get cardOptionPrice() {
    let { optionsPrices, cardLevel } = this.args;
    return optionsPrices?.[cardLevel]?.value;
  }

  get isCardFree() {
    return this.monthlyCost === 0;
  }
}
