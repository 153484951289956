export default {
  "container": "cCb",
  "form-container": "cCy",
  "title": "cCU",
  "title-border": "cCj",
  "tabs-container": "cCW",
  "preview-container": "cCu",
  "email-preview-container": "ckS",
  "close-button": "ckc btn btn--icon-only btn--tertiary btn--large"
};
