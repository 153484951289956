/* import __COLOCATED_TEMPLATE__ from './customization-logo.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsAccountReceivableOnboardingCustomizationLogo extends Component {
  @action
  handleConfirm() {
    this.args.transitionToNext();
  }
}
