export default {
  "card": "ctR",
  "text-container": "ctQ",
  "text-title": "cte title-3",
  "text-subtitle": "ctB body-1",
  "text-cta": "ctE body-1",
  "text-cta-icon": "ctd",
  "image": "ctI",
  "image-illustration": "ctt"
};
