export default {
  "members-loading": "cgL",
  "members-loading--ff": "cgT",
  "header": "cgz",
  "header-search-bar": "cga",
  "body": "cgH",
  "members": "cgO",
  "placeholder-container": "cgm",
  "icon": "cgp",
  "details": "cgx",
  "member-loading": "cgw",
  "member-loading--no-ff": "cgN",
  "body-members": "cgv",
  "body-details": "cgo"
};
