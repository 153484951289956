export default {
  "color-option": "cIS",
  "color-dot": "cIc",
  "any": "cIq",
  "red": "cIZ",
  "orange": "cIR",
  "yellow": "cIQ",
  "green": "cIe",
  "blue": "cIB",
  "purple": "cIE",
  "pink": "cId",
  "black": "cII",
  "grey": "cIt",
  "brown": "cIM",
  "color-name": "cIP"
};
