/* import __COLOCATED_TEMPLATE__ from './scope-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import {
  DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION,
  DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION_OLD,
  DEFAULT_CUSTOM_PERMISSIONS_TEAM,
  DEFAULT_CUSTOM_PERMISSIONS_TEAM_OLD,
} from 'qonto/constants/membership';

export default class ScopeSelectionComponent extends Component {
  @service segment;
  @service store;
  @service abilities;

  @reads('args.context.member.team.name') team;

  @action
  handleSelectScope(scope) {
    if (scope !== this.args.context.member.customPermissions?.scope) {
      if (!this.args.context.isEditingMember) {
        // In the creation mode we reset the allowed bank accounts ids when the scope changes
        this.resetAllowedBankAccountsIds();
      }

      let defaultPermissions = this.abilities.can('view manager split permission invite')
        ? {
            team: DEFAULT_CUSTOM_PERMISSIONS_TEAM,
            organization: DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION,
          }
        : {
            team: DEFAULT_CUSTOM_PERMISSIONS_TEAM_OLD,
            organization: DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION_OLD,
          };

      this.args.context.member.customPermissions = {
        scope,
        groups: scope === 'team' ? defaultPermissions.team : defaultPermissions.organization,
      };
    }

    if (!this.args.context.isEditingMember) {
      this.segment.track('team_invite_manager-scope_set');
    }
    this.args.transitionToNext();
  }

  resetAllowedBankAccountsIds() {
    if (this.args.context && this.args.context.member) {
      this.args.context.member.allowedBankAccountsIds = undefined;
    }
  }
}
