export default {
  "card": "cJO",
  "focus": "cJm",
  "title-format": "cJp",
  "header": "cJx",
  "headings": "cJw",
  "title": "cJN title-4",
  "main": "cJv title-3",
  "footer": "cJo"
};
