export default {
  "item": "cFD",
  "selected": "cFV",
  "hover-revised": "cFL",
  "asset-container": "cFT",
  "asset": "cFz",
  "revoked-icon": "cFa",
  "pending-icon": "cFH",
  "revoked": "cFO",
  "revised-revoke": "cFm",
  "pending": "cFp",
  "revised-pending": "cFx",
  "invitable": "cFw",
  "info": "cFN",
  "name": "cFv",
  "email": "cFo",
  "actions": "cFn",
  "invitable-member-delete": "cFi",
  "invitable-member-invite": "cFA"
};
