export default {
  "container": "cBL",
  "content": "cBT",
  "quote-information-item": "cBz",
  "divider": "cBa",
  "tooltip": "cBH",
  "amount": "cBO title-4",
  "align-left": "cBm",
  "sticky-panel": "cBp"
};
