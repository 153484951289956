export default {
  "row": "ik",
  "row-active": "iJ",
  "cell": "is body-2",
  "terminated": "iF",
  "amount": "ig body-1",
  "item-name-cell": "iD",
  "status-icon": "iV",
  "item-name-container": "iL",
  "item-info": "iT",
  "name": "iz body-1",
  "status-displayed": "ia caption"
};
