export default {
  "container": "bo",
  "disabled-option": "bn",
  "content": "bi",
  "spinner": "bA",
  "subtitle": "bY",
  "card-panel": "bh",
  "card-icon": "bf",
  "card-description": "bK",
  "details-table": "bG",
  "caption": "br",
  "tooltip-icon": "bb",
  "tooltip-icon-right": "by",
  "card-monthly-cost": "bU",
  "card-monthly-discounted-cost": "bj",
  "strikethrough": "bW",
  "purple-text": "bu",
  "budget-label": "yS",
  "card-cost-label": "yc",
  "card-billing-account": "yq"
};
