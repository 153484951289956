export default {
  "upsell-cta": "ccn",
  "discover-badge": "cci",
  "role-selection-item": "ccA",
  "unavailable": "ccY",
  "role-headline": "cch",
  "illustration": "ccf",
  "role-description": "ccK",
  "description-item": "ccG",
  "description-icon": "ccr",
  "description-text": "ccb",
  "current-role": "ccy"
};
