export default {
  "wrapper": "clC",
  "content": "clk",
  "back": "clJ btn btn--tertiary",
  "heading": "cls",
  "heading-content": "clF",
  "logo": "clg",
  "placeholder": "clD",
  "description": "clV body-2",
  "cta": "clL"
};
