/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { VALID_EMAIL_REGEXP } from 'qonto/constants/invoice-subscriptions';
import isFunction from 'qonto/utils/is-function';

export default class ReceivableInvoicesTableComponent extends Component {
  @service organizationManager;
  @service intl;
  @service modals;
  @service segment;

  @reads('organizationManager.organization') organization;
  @reads('organizationManager.membership') membership;
  @reads('args.data.subscription.emailTemplate') emailTemplate;

  @tracked isDirty = false;
  @tracked copyToSelf = this.emailTemplate?.copyToSelf ?? true;
  @tracked sendTo =
    this.emailTemplate?.sendTo.join(',') === '-' ? '' : this.emailTemplate?.sendTo.join(',');
  @tracked emailSubject =
    this.emailTemplate?.emailTitle ??
    this.intl.t('recurring-invoices.new.modal.email-details.subject-placeholder', {
      language: this.emailPreviewLanguage,
    });
  @tracked emailMessage =
    this.emailTemplate?.emailBody ??
    this.intl.t('recurring-invoices.new.modal.email-details.message-placeholder', {
      language: this.emailPreviewLanguage,
    });

  contactEmail = this.args.data.settings?.contactEmail || this.membership.email;

  get emailMessageLines() {
    return this.emailMessage
      ? this.emailMessage.replace('\r\n', '\n').replace('\r', '\n').split('\n')
      : [];
  }

  get logo() {
    return this.organization.get('isDefaultAvatar') !== null &&
      this.organization.get('isDefaultAvatar') === false
      ? this.organization.picture
      : null;
  }

  get emailPreviewLanguage() {
    return this.args.data.subscription.locale ?? this.args.data.subscription.get('customer.locale');
  }

  get emailPreviewData() {
    return {
      dueDate: dayjs(this.args.data.subscription.startDate).add(
        this.args.data.subscription.paymentTermsDays,
        'days'
      ),
      amountDue: this.args.data.subscription.totalAmount,
      number: this.args.data.settings.invoiceNextNumberFormatted,
    };
  }

  get emailsArray() {
    if (!this.sendTo) return [];

    //Delete space after ',' char,
    //Delete ',' char when first or last param
    //Return an array of emails from a string where emails are separated by a comma
    let emailsArray = this.sendTo.replace(/^,/, '').replace(/,$/, '').split(',');

    return emailsArray.map(email => email.trim());
  }

  get emailSubjectErrorMessage() {
    let errors = this.args.data.subscription.errors.filter(({ attribute }) =>
      attribute.includes('emailTemplate/subject')
    );
    let isRequiredError = errors.some(({ message }) => message === 'required');
    let isInvalidError = errors.some(({ message }) => message === 'invalid');

    if (isRequiredError) {
      return this.intl.t('receivable-invoices.share-email.required-field');
    } else if (isInvalidError) {
      return this.intl.t('receivable-invoices.share-email.field-send-to.error');
    }
  }

  get sendToErrorMessage() {
    let errors = this.args.data.subscription.errors.filter(({ attribute }) =>
      attribute.includes('emailTemplate/sendto')
    );
    let isRequiredError = errors.some(({ message }) => message === 'required');
    let isInvalidError = errors.some(({ message }) => message === 'invalid');

    if (isRequiredError) {
      return this.intl.t('receivable-invoices.share-email.required-field');
    } else if (isInvalidError) {
      return this.intl.t('receivable-invoices.share-email.field-send-to.error');
    }
  }

  @action updateField(field, value) {
    this[field] = value;
    this.isDirty = true;

    let errorPointers = {
      sendTo: 'emailTemplate/sendto',
      emailSubject: 'emailTemplate/subject',
      emailMessage: 'emailTemplate/body',
    };

    if (errorPointers[field]) {
      this.args.data.subscription.errors
        .filter(({ attribute }) => attribute.includes(errorPointers[field]))
        .forEach(({ attribute }) => this.args.data.subscription.errors.remove(attribute));
    }
  }

  onSaveTask = dropTask(async closePopup => {
    if (!this.#validateForm()) {
      if (isFunction(closePopup)) await closePopup();
      return;
    }

    this.args.data.subscription.emailTemplate = {
      emailTitle: this.emailSubject,
      emailBody: this.emailMessage,
      sendTo: this.emailsArray,
      copyToSelf: this.copyToSelf,
    };
    if (isFunction(closePopup)) await closePopup();
    await this.args.close();
  });

  onCloseTask = dropTask(async () => {
    if (this.isDirty) {
      let result = await this.modals.open('popup/confirmation', {
        title: this.intl.t('recurring-invoices.new.modal.email-details.confirmation-popup.title'),
        description: this.intl.t(
          'recurring-invoices.new.modal.email-details.confirmation-popup.subtitle'
        ),
        confirm: this.intl.t(
          'recurring-invoices.new.modal.email-details.confirmation-popup.leave-cta'
        ),
        cancel: this.intl.t(
          'recurring-invoices.new.modal.email-details.confirmation-popup.cancel-cta'
        ),
        confirmTask: this.onSaveTask,
        cancelTask: this.abortTask,
      });

      if (result === 'cancel' || result === 'close') {
        this.segment.track('recurring-invoices_email-setup_canceled', {
          origin: result,
        });
      }
    } else {
      await this.args.close();
    }
  });

  abortTask = dropTask(async close => {
    this.segment.track('recurring-invoices_email-setup_canceled', {
      origin: 'cancel',
    });

    await close();
    await this.args.close();
  });

  #validateForm() {
    let isValid = true;

    // Email is required
    if (!this.emailsArray.length) {
      this.args.data.subscription.errors.add('emailTemplate/sendto', 'required');
      isValid = false;
    }

    // All emails must be valid emails
    if (this.emailsArray.some(email => !VALID_EMAIL_REGEXP.test(email))) {
      this.args.data.subscription.errors.add('emailTemplate/sendto', 'invalid');
      isValid = false;
    }

    // Email subject is required
    if (!this.emailSubject) {
      this.args.data.subscription.errors.add('emailTemplate/subject', 'required');
      isValid = false;
    }

    return isValid;
  }
}
