export default {
  "container": "cIC",
  "container--collapsed": "cIk",
  "spin": "cIJ",
  "cta-tooltip": "cIs",
  "cta--collapsed": "cIF",
  "cta--expanded": "cIg",
  "collapsed-svg-icon": "cID",
  "expanded-svg-icon": "cIV",
  "generate-logo-cta": "cIL",
  "badge": "cIT",
  "scaleDown": "cIz",
  "fadeIn": "cIa",
  "textRiseButton": "cIH",
  "cta-description": "cIO",
  "textRiseDescription": "cIm"
};
