export default {
  "container": "cgU",
  "close-button": "cgj",
  "content-wrapper": "cgW",
  "main": "cgu",
  "description": "cDS",
  "calculation-item": "cDc",
  "total-km": "cDq",
  "calculation-details": "cDZ",
  "btn": "cDR"
};
