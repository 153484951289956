export default {
  "container": "cXw",
  "form": "cXN",
  "form-header": "cXv",
  "form-subtitle": "cXo",
  "form-input": "cXn",
  "left-input": "cXi",
  "right-input": "cXA",
  "label-fix": "cXY",
  "x-validated-input__header": "cXh",
  "form-dropzone": "cXf",
  "form-footer": "cXK",
  "submit-cta": "cXG",
  "checklist": "cXr",
  "about-you-form": "cXb",
  "name-inputs": "cXy",
  "birth-inputs": "cXU"
};
