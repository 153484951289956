export default {
  "header": "YM",
  "header-top": "YP",
  "header-inner": "Yl",
  "disclaimer": "YX mb-16",
  "header-main-wrapper": "YC",
  "header-main": "Yk",
  "logo": "YJ mr-16",
  "header-right": "Ys",
  "partner-description": "YF body-2"
};
