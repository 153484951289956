export default {
  "filter": "iU",
  "expression": "ij",
  "conditional-wrapper": "iW mr-8",
  "conditional-text": "iu",
  "property-select": "AS",
  "operator-select": "Ac",
  "error-message": "Aq",
  "values-field": "AZ",
  "input-placeholder": "AR",
  "filter-actions": "AQ",
  "label-color": "Ae",
  "button-container": "AB"
};
