/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

import { ROLES } from 'qonto/constants/membership';

export default class FlowsMemberRoleSelectionItemComponent extends Component {
  badgeHighlight = Badge;

  get isModularPricing() {
    return (
      (this.args.key === ROLES.MANAGER || this.args.key === ROLES.ADVANCED_MANAGER) &&
      this.args.hasModularPricing
    );
  }
}
