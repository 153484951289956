/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeStatus } from '@repo/design-system-kit';

import { INSURANCE_CONTRACT_FREQUENCY } from 'qonto/constants/insurance-hub';
import { getInsuranceContractStatusProps } from 'qonto/utils/insurance-hub/insurance-contract';

export default class InsuranceHubTableItemComponent extends Component {
  badgeStatus = BadgeStatus;

  @service intl;
  @service localeManager;

  get paymentFrequency() {
    let { paymentFrequency } = this.args.item;

    switch (paymentFrequency) {
      case INSURANCE_CONTRACT_FREQUENCY.MONTHLY:
        return this.intl.t('insurance-hub.policies.price.monthly');

      case INSURANCE_CONTRACT_FREQUENCY.QUARTERLY:
        return this.intl.t('insurance-hub.policies.price.quarterly');

      case INSURANCE_CONTRACT_FREQUENCY.YEARLY:
        return this.intl.t('insurance-hub.policies.price.yearly');
    }
  }

  get insuranceContractStatusProps() {
    let { status } = this.args.item;

    return getInsuranceContractStatusProps({ intl: this.intl, status });
  }

  get renewalDate() {
    let { isExpired, renewalDate } = this.args.item;

    return isExpired || !renewalDate
      ? '-'
      : dateToken({
          date: renewalDate,
          token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
          locale: this.localeManager.locale,
        });
  }
}
