export default {
  "dates-filter": "AE",
  "dates-wrapper": "Ad",
  "date-interval": "AI",
  "date-field-wrapper": "At",
  "dates-conditional": "AM",
  "placeholder": "AP",
  "error": "Al",
  "error-message": "AX body-2"
};
