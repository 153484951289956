/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer, Tag } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { SDD_MANDATE_STATUS } from 'qonto/constants/direct-debit-collections';
import { DIRECT_DEBIT_SUBSCRIPTION_STATUS } from 'qonto/constants/direct-debit-subscriptions';
import { apiBaseURL, receivableInvoiceNamespace } from 'qonto/constants/hosts';
import { INVOICE_SUBSCRIPTION_STATUS_COLORS, STATUS } from 'qonto/constants/invoice-subscriptions';
import { Frequency } from 'qonto/react/components/invoice-subscriptions/frequency';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvoiceSubscriptionsSidebarComponent extends Component {
  frequency = Frequency;
  disclaimerInline = Disclaimer.Inline;

  tag = Tag;

  @service intl;
  @service router;
  @service modals;
  @service segment;
  @service networkManager;
  @service sentry;
  @service toastFlashMessages;
  @service store;
  @service organizationManager;

  get isLoading() {
    return (
      this.args.fetchCustomerTask?.isRunning ||
      this.args.fetchRelatedInvoicesTask?.isRunning ||
      this.args.fetchSubscriptionMandateTask?.isRunning
    );
  }

  get customer() {
    return this.args.fetchCustomerTask.lastSuccessful?.value || {};
  }

  get relatedInvoices() {
    return this.args.fetchRelatedInvoicesTask.lastSuccessful?.value?.invoices || [];
  }

  get lastInvoiceSubscription() {
    return this.args.fetchRelatedInvoicesTask.lastSuccessful?.value?.lastInvoiceSubscription;
  }

  get showSddLink() {
    return [
      DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE,
      DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED,
      DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS,
    ].includes(this.lastInvoiceSubscription?.status);
  }

  get directDebitCollectionMandate() {
    return this.args.fetchSubscriptionMandateTask?.lastSuccessful?.value || {};
  }

  get showSddMandateWarning() {
    let { status, directDebitEnabled } = this.args.document;
    return (
      directDebitEnabled &&
      this.args.isSddActivated &&
      (this.directDebitCollectionMandate?.status === SDD_MANDATE_STATUS.PENDING ||
        this.directDebitCollectionMandate?.status === SDD_MANDATE_STATUS.DECLINED ||
        !this.directDebitCollectionMandate?.id) &&
      (status === STATUS.SCHEDULED || status === STATUS.ACTIVE)
    );
  }

  get sddMandateWarningText() {
    if (this.directDebitCollectionMandate?.status === SDD_MANDATE_STATUS.PENDING) {
      return this.intl.t(
        'recurring-invoices.list.sidebar.sdd-warning.missing-signature.disclaimer'
      );
    }
    return this.intl.t('recurring-invoices.list.sidebar.sdd-warning.missing-sdd.disclaimer');
  }

  get sddMandateWarningTextCta() {
    if (this.directDebitCollectionMandate?.status === SDD_MANDATE_STATUS.PENDING) {
      return this.intl.t('recurring-invoices.list.sidebar.sdd-warning.missing-signature.cta');
    }
    return this.intl.t('recurring-invoices.list.sidebar.sdd-warning.missing-sdd.cta');
  }

  get sddMandateWarningCtaRoute() {
    if (this.directDebitCollectionMandate?.status === SDD_MANDATE_STATUS.PENDING) {
      return 'invoice-subscriptions.sdd.payment-link';
    }
    return 'invoice-subscriptions.sdd.setup';
  }

  @action
  dateOrDash(date) {
    if (!date) return '-';

    return dateToken({ date, locale: this.intl.locale, token: DATE_FORMAT_TOKENS.DATE_YEAR_S });
  }

  get #statusCopy() {
    return {
      [STATUS.SCHEDULED]: this.intl.t('recurring-invoices.statuses.scheduled'),
      [STATUS.ACTIVE]: this.intl.t('recurring-invoices.statuses.active'),
      [STATUS.FINISHED]: this.intl.t('recurring-invoices.statuses.completed'),
      [STATUS.CANCELED]: this.intl.t('recurring-invoices.statuses.canceled'),
      [STATUS.SUSPENDED]: this.intl.t('recurring-invoices.statuses.suspended'),
    };
  }

  cancelSubscriptionTask = dropTask(async () => {
    let { id, status } = this.args.document;

    try {
      await this.networkManager.request(
        `${apiBaseURL}/${receivableInvoiceNamespace}/receivable_invoices/subscriptions/${id}/cancel`,
        {
          method: 'POST',
        }
      );

      this.segment.track('recurring-invoices_cancel_confirmed', { success: true, status });

      this.router.transitionTo('invoice-subscriptions.index', {
        queryParams: {
          highlighted: id,
          status: [STATUS.CANCELED, STATUS.FINISHED].join(','),
        },
      });

      this.toastFlashMessages.toastInfo(this.intl.t('recurring-invoices.toasts.info.canceled'));
    } catch (error) {
      this.segment.track('recurring-invoices_cancel_confirmed', { success: false, status });
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      this.cancelModal.close();
    }
  });

  @action
  showCancelPopup() {
    this.segment.track('recurring-invoices_cancel_clicked', { status: this.args.document.status });
    this.cancelModal = this.modals.open('popup/destructive', {
      title: this.intl.t('recurring-invoices.list.sidebar.modal.confirm-cancel.title'),
      description: this.intl.t('recurring-invoices.list.sidebar.modal.confirm-cancel.subtitle'),
      confirm: this.intl.t('recurring-invoices.list.sidebar.modal.confirm-cancel.confirm'),
      cancel: this.intl.t('btn.back'),
      confirmTask: this.cancelSubscriptionTask,
    });
  }

  get isEditable() {
    return [STATUS.ACTIVE, STATUS.SCHEDULED, STATUS.SUSPENDED].includes(this.args.document.status);
  }

  @action
  handleEdit() {
    this.segment.track('recurring-invoices_edit_clicked', { status: this.args.document.status });
    this.router.transitionTo('invoice-subscriptions.edit', this.args.document.id);
  }

  get emailReceivers() {
    let { sendTo } = this.args.document.emailTemplate;

    if (typeof sendTo === 'string') return sendTo;

    return sendTo?.join(' ') || '';
  }

  get status() {
    let { status } = this.args.document;

    return {
      displayStatus: this.#statusCopy[status],
      color: INVOICE_SUBSCRIPTION_STATUS_COLORS[status],
    };
  }

  get showPaymentMethod() {
    let { status, directDebitEnabled } = this.args.document;
    return directDebitEnabled && (status === STATUS.SCHEDULED || status === STATUS.ACTIVE);
  }

  reactivateTask = dropTask(async () => {
    try {
      if (this.args.document.directDebitEnabled) {
        let mandate = await this.args.document.directDebitCollectionMandate;

        if ([SDD_MANDATE_STATUS.CANCELED, SDD_MANDATE_STATUS.DECLINED].includes(mandate?.status)) {
          return this.router.transitionTo(
            'invoice-subscriptions.sdd.setup',
            this.args.document.id,
            {
              queryParams: {
                reactivate: true,
              },
            }
          );
        }
      }

      await this.args.document.reactivate();

      this.toastFlashMessages.toastInfo(this.intl.t('recurring-invoices.toasts.info.reactivated'));
      this.segment.track('recurring-invoices_reactivate_clicked', { success: true });
    } catch (error) {
      this.segment.track('recurring-invoices_reactivate_clicked', { success: false });
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  suspendTask = dropTask(async () => {
    try {
      await this.args.document.suspend();

      this.toastFlashMessages.toastInfo(this.intl.t('recurring-invoices.toasts.info.suspended'));
      this.segment.track('recurring-invoices_suspend_clicked', { success: true });
    } catch (error) {
      this.segment.track('recurring-invoices_suspend_clicked', { success: false });
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
