export default {
  "container": "GX",
  "back-button": "GC",
  "card-list": "Gk",
  "upsell-badge": "GJ",
  "pricing": "Gs",
  "pricing-period": "GF",
  "ad-modal-button": "Gg",
  "eligibility-disclaimer": "GD",
  "feature": "GV",
  "highlighted": "GL"
};
