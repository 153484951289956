export default {
  "wrapper": "vk",
  "content": "vJ",
  "compact": "vs",
  "full-width": "vF",
  "left": "vg",
  "right-image": "vD",
  "right-lottie": "vV",
  "badge": "vL",
  "badge-icon": "vT",
  "animation": "vz",
  "hidden": "va"
};
