/* import __COLOCATED_TEMPLATE__ from './quote.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { allSettled, dropTask, restartableTask, timeout } from 'ember-concurrency';
import { TrackedObject } from 'tracked-built-ins';

import {
  DEFAULT_SOURCE_AMOUNT,
  ERROR_CODE,
  QUOTE_CREATION_STATUS,
  RATE_TYPE,
  TYPE,
} from 'qonto/constants/international-out/quote';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { extendCurrency } from 'qonto/utils/currency';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { formatExchangeRate } from 'qonto/utils/international-out/format';
import scrollIntoView from 'qonto/utils/scroll-into-view';
import transformKeys from 'qonto/utils/transform-keys';
import QuoteValidations from 'qonto/validations/quote';

function createCurrencyPlaceholder(currencyCode) {
  return {
    currencyCode,
    countryCode: currencyCode.slice(0, -1),
    suggestionPriority: null,
  };
}

function getInitialPayload({ quote }) {
  if (quote) {
    return {
      sourceAmount: quote.type === TYPE.SOURCE ? quote.sourceAmount : null,
      targetAmount: quote.type === TYPE.TARGET ? quote.targetAmount : null,
    };
  }
}

function getInitialState({ bankAccount, quote, settings }) {
  let { sourceCurrency, targetCurrency } = settings.preferred;

  let initialState = {
    bankAccount,
    sourceCurrency,
    targetCurrency,
  };

  if (quote) {
    return {
      ...initialState,
      sourceAmount: quote?.type === TYPE.SOURCE ? quote.sourceAmount : null,
      targetAmount: quote?.type === TYPE.TARGET ? quote.targetAmount : null,
      targetCurrency: quote?.targetCurrency,
    };
  }

  return initialState;
}

function getInitialSourceCurrency({ quote, settings }) {
  if (quote) {
    return createCurrencyPlaceholder(quote.sourceCurrency);
  }

  return createCurrencyPlaceholder(settings.preferred.sourceCurrency);
}

function getInitialTargetCurrency({ quote, settings }) {
  if (quote) {
    return createCurrencyPlaceholder(quote.targetCurrency);
  }

  return createCurrencyPlaceholder(settings.preferred.targetCurrency);
}

class QuoteModel extends EmberObject.extend(QuoteValidations) {
  @service abilities;
  @service intl;

  @tracked type = null;
  @tracked bankAccount = null;
  @tracked sourceAmount = null;
  @tracked targetAmount = null;
  @tracked sourceCurrency = null;
  @tracked targetCurrency = null;
  @tracked rate = null;
  @tracked fees = null;

  errors = new TrackedObject();

  get shouldValidateTotalAmount() {
    return this.abilities.can('see balance bankAccount');
  }

  get totalAmount() {
    return Number(this.sourceAmount || 0) + Number(this.fees?.total || 0);
  }

  clearErrors() {
    Object.keys(this.errors).forEach(attribute => delete this.errors[attribute]);
  }
}

export default class FlowsTransfersInternationalOutNewQuoteComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service errors;
  @service internationalOutManager;
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service toastFlashMessages;

  @tracked sourceCurrencies = [getInitialSourceCurrency(this.args.context)];
  @tracked targetCurrencies = [getInitialTargetCurrency(this.args.context)];

  @tracked lastQuoteCreationResult = null;

  constructor() {
    super(...arguments);

    this.segment.track(EVENTS.START_FLOW);

    this.quoteModel = QuoteModel.create(
      getOwner(this).ownerInjection(),
      getInitialState(this.args.context)
    );

    allSettled([
      this.getTargetCurrenciesTask.perform(),
      this.createAndUpdateQuoteTask.perform(getInitialPayload(this.args.context)),
    ])
      .then(([targetCurrenciesSettled]) => {
        if (targetCurrenciesSettled.state === 'fulfilled') {
          this.targetCurrencies = targetCurrenciesSettled.value;
        }
      })
      .catch(ignoreCancelation);
  }

  get actionTask() {
    if (this.hasFailed) {
      return this.onRetryTask;
    }

    return this.onTransitionTask;
  }

  get formattedExchangeRate() {
    let value = formatExchangeRate(this.quoteModel.rate?.value || 0);
    let [, decimalPart] = value.split('.');
    return {
      value,
      decimalPlacesCount: decimalPart ? decimalPart.length : 0,
    };
  }

  get hasFailed() {
    return this.lastQuoteCreationResult?.status === QUOTE_CREATION_STATUS.UNEXPECTED_ERROR;
  }

  get extendedAndSortedSourceCurrencies() {
    return this.#extendCurrencies(this.sourceCurrencies)?.sort((firstCurrency, secondCurrency) =>
      firstCurrency.currencyCode.localeCompare(secondCurrency.currencyCode)
    );
  }

  get extendedAndSortedTargetCurrencies() {
    return this.#extendCurrencies(this.targetCurrencies)?.sort((firstCurrency, secondCurrency) =>
      firstCurrency.currencyCode.localeCompare(secondCurrency.currencyCode)
    );
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get shouldDisplayButton() {
    return this.quoteModel.errors?.global?.code !== ERROR_CODE.NO_PAYMENT_OPTION_AVAILABLE;
  }

  get targetCurrencyNotice() {
    let { fees, targetCurrency } = this.quoteModel;

    if (fees === null) {
      return null;
    }

    switch (targetCurrency) {
      case 'EUR':
        return this.intl.t('international-out.quote.information.eur');
      case 'USD':
        return this.intl.t('international-out.quote.information.usd');
      case 'MYR':
        return this.intl.t('international-out.quote.information.myr');
      default:
        return null;
    }
  }

  @action
  updateBankAccount(account) {
    this.args.context.bankAccount = account;
    this.quoteModel.bankAccount = account;
  }

  createQuoteTask = dropTask(
    async ({
      targetAmount = null,
      sourceAmount = DEFAULT_SOURCE_AMOUNT,
      targetCurrency = this.quoteModel.targetCurrency,
      sourceCurrency = this.quoteModel.sourceCurrency,
    } = {}) => {
      let status = QUOTE_CREATION_STATUS.SUCCESS;
      let payload = {
        ...(sourceAmount && { sourceAmount: Number(sourceAmount) }),
        ...(targetAmount && { targetAmount: Number(targetAmount) }),
        sourceCurrency,
        targetCurrency,
      };

      try {
        return await this.internationalOutManager.createQuote(payload);
      } catch (error) {
        if (error.errors?.[0] && Object.values(ERROR_CODE).includes(error.errors[0].code)) {
          status = QUOTE_CREATION_STATUS.VALIDATION_ERROR;

          let { code, meta, source } = transformKeys(error.errors[0]);

          let {
            ABOVE_MAXIMUM_AMOUNT,
            BELOW_MINIMUM_AMOUNT,
            NO_PAYMENT_OPTION_AVAILABLE,
            TARGET_AMOUNT_VOLATILE,
          } = ERROR_CODE;

          let errorCodesMapping = {
            [ABOVE_MAXIMUM_AMOUNT]: {
              attribute: source?.pointer?.slice(1),
              message: this.intl.t('international-out.quote.errors.amount.too-high', {
                formattedAmount: meta?.formattedAmount,
              }),
            },
            [BELOW_MINIMUM_AMOUNT]: {
              attribute: source?.pointer?.slice(1),
              message: this.intl.t('international-out.quote.errors.amount.too-low', {
                formattedAmount: meta?.formattedAmount,
              }),
            },
            [NO_PAYMENT_OPTION_AVAILABLE]: {
              message: this.intl.t('international-out.quote.errors.currency.unavailable', {
                targetCurrency: this.quoteModel.targetCurrency,
              }),
            },
            [TARGET_AMOUNT_VOLATILE]: {
              message: this.intl.t('international-out.quote.errors.exchange-rate.floating', {
                sourceCurrency: this.quoteModel.sourceCurrency,
              }),
            },
          };

          if (code in errorCodesMapping) {
            let { attribute, message } = errorCodesMapping[code];
            this.quoteModel.errors[attribute ?? 'global'] = {
              code,
              message,
            };
          }

          return;
        }

        status = QUOTE_CREATION_STATUS.UNEXPECTED_ERROR;

        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(
          this.errors.messageForStatus(error) || this.intl.t('toasts.errors.generic')
        );
      } finally {
        this.lastQuoteCreationResult = {
          status,
          payload,
        };
      }
    }
  );

  createAndUpdateQuoteTask = dropTask(async payload => {
    this.quoteModel.clearErrors();

    let response = await this.createQuoteTask.perform(payload);

    if (!response) return;

    let { quote, fees } = response;

    this.args.context.fees = fees;

    if (!isEmpty(this.quoteModel.sourceAmount) || !isEmpty(this.quoteModel.targetAmount)) {
      this.#setQuote(quote, fees);
    } else {
      this.#setRate(quote.rate);
    }
  });

  getTargetCurrenciesTask = dropTask(async () => {
    try {
      return await this.internationalOutManager.getTargetCurrencies();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  onAmountUpdateTask = dropTask(async (amount, type) => {
    let amountAttribute = type === TYPE.SOURCE ? 'sourceAmount' : 'targetAmount';

    await timeout(DEBOUNCE_MS);

    this.quoteModel[amountAttribute] = amount;

    let { [amountAttribute]: amountValidations } = this.quoteModel.validations.attrs;
    if (isEmpty(amount) || amountValidations.isInvalid) {
      return;
    }

    this.segment.track(EVENTS.INPUT_AMOUNT, { amount_input_type: type });

    await this.createAndUpdateQuoteTask.perform({
      sourceAmount: type === TYPE.SOURCE ? amount : null,
      targetAmount: type === TYPE.TARGET ? amount : null,
    });
  });

  onRetryTask = dropTask(async () => {
    if (!this.lastQuoteCreationResult) return;

    let { payload } = this.lastQuoteCreationResult;

    await this.createAndUpdateQuoteTask.perform({
      ...payload,
      sourceAmount: payload.sourceAmount || null,
      targetAmount: payload.targetAmount || null,
    });
  });

  onSourceAmountUpdateTask = restartableTask(async amount => {
    await this.onAmountUpdateTask.perform(amount, TYPE.SOURCE);
  });

  onTargetAmountUpdateTask = restartableTask(async amount => {
    await this.onAmountUpdateTask.perform(amount, TYPE.TARGET);
  });

  onTargetCurrencyUpdateTask = dropTask(async currencyCode => {
    let { quoteModel } = this;
    let { sourceAmount, targetAmount, targetCurrency, type } = quoteModel;

    if (targetCurrency === currencyCode) return;

    quoteModel.targetCurrency = currencyCode;

    this.segment.track(EVENTS.SELECT_CURRENCY, { currency_type: TYPE.TARGET });

    let { sourceAmount: sourceAmountValidations, targetAmount: targetAmountValidations } =
      quoteModel.validations.attrs;

    if (sourceAmountValidations.isInvalid || targetAmountValidations.isInvalid) return;

    let payload;

    if (type) {
      payload = {
        sourceAmount: type === TYPE.SOURCE ? sourceAmount : null,
        targetAmount: type === TYPE.TARGET ? targetAmount : null,
      };
    }

    await this.createAndUpdateQuoteTask.perform(payload);
  });

  onTransitionTask = dropTask(async () => {
    await this.quoteModel.validate();

    let { sourceAmount, targetAmount, totalAmount } = this.quoteModel.validations.attrs;

    let hasValidationErrors =
      isEmpty(this.quoteModel.sourceAmount) ||
      isEmpty(this.quoteModel.targetAmount) ||
      sourceAmount.isInvalid ||
      targetAmount.isInvalid ||
      totalAmount.isInvalid;

    let hasServerErrors = Boolean(Object.keys(this.quoteModel.errors).length);

    let isQuoteCreated = Boolean(this.args.context.quote);

    if (hasValidationErrors || hasServerErrors || !isQuoteCreated) {
      this.#scrollToError();
      return;
    }

    this.args.transitionToNext();
  });

  #extendCurrencies = currencies =>
    currencies.map(currency => extendCurrency({ currency, locale: this.localeManager.locale }));

  #scrollToError() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  #setQuote = (quote, fees) => {
    this.args.context.quote = quote;

    let { sourceAmount, targetAmount, rate, rateType, type } = quote;

    this.quoteModel.setProperties({
      type,
      sourceAmount,
      targetAmount,
      rate: {
        value: rate,
        type: rateType,
      },
      fees: {
        rate: fees.rate,
        total: fees.total,
      },
    });
  };

  #setRate = rate => {
    this.args.context.quote = null;

    this.quoteModel.setProperties({
      rate: {
        value: rate,
        type: RATE_TYPE.FIXED,
      },
    });
  };
}
