/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class SubscriptionChangeSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service segment;
  @service subscriptionManager;
  @service organizationManager;

  constructor() {
    super(...arguments);
    this.sendTrackingEventOnLoad();
  }

  get hasModularPricing() {
    return this.organizationManager.organization.hasModularPricing;
  }

  sendTrackingEventOnLoad() {
    let context = this.args.context;
    this.segment.track('plans_change_confirmation_success', {
      recurrence: context.recurrence,
      trial_state: this.trackingTrialState,
      target_plan: this.hasModularPricing
        ? context.subscription.product.get('code')
        : context.subscription.pricePlan.get('code'),
      current_plan: context.currentPricePlanCode,
    });
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get trackingTrialState() {
    let trialState = TRACKING_TRIAL_STATE.NONE;

    if (this.args.context.isFreeTrial) {
      trialState = TRACKING_TRIAL_STATE.FREE_TRIAL;
    }

    if (this.hasInitialTrial) {
      trialState = TRACKING_TRIAL_STATE.INITIAL_TRIAL;
    }

    return trialState;
  }

  get CTAText() {
    if (this.args.context.refererPage) {
      return this.intl.t('upsell.discover.trial.success.cta');
    }
    return this.intl.t('subscription.change.success.cta');
  }

  get isConnectUpsell() {
    return this.args.context?.refererPage?.includes('connect');
  }
}
