export default {
  "header-cell": "id caption-bold",
  "sortable": "iI",
  "animated": "it",
  "fadein-item": "iM",
  "animated-cell": "iP",
  "col1": "il",
  "header-content": "iX caption-bold",
  "active": "iC"
};
