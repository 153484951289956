/* import __COLOCATED_TEMPLATE__ from './de-customization-custom-messages.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsAccountReceivableOnboardingDeCustomizationCustomMessages extends Component {
  @action
  handleConfirm() {
    this.args.backToStep('summary');
  }
}
