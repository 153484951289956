export default {
  "container": "rA",
  "container-metal": "rY",
  "content": "rh",
  "button": "rf",
  "embossed-selector": "rK",
  "carousel-container": "rG",
  "carousel": "rr",
  "asset": "rb",
  "poster": "ry",
  "video-embossed": "rU",
  "video": "rj",
  "active": "rW",
  "embossed": "ru",
  "safari": "bS",
  "poster-open": "bc"
};
