export default {
  "container": "clZ",
  "logo": "clR",
  "title": "clQ title-4",
  "description": "cle body-2",
  "metadata": "clB",
  "risks-container": "clE",
  "label": "cld caption",
  "risks": "clI",
  "tag": "clt tag"
};
