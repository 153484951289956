export default {
  "wrapper": "cIp",
  "close-button": "cIx",
  "content-wrapper": "cIw",
  "main": "cIN",
  "main-content": "cIv",
  "description": "cIo",
  "mt-26": "cIn",
  "mt-62": "cIi",
  "user-prompt": "cIA",
  "user-prompt-inappropriate-warning": "cIY",
  "logo-preview": "cIh",
  "static-loader": "cIf",
  "loading-state-illustration": "cIK",
  "sticky-panel": "cIG"
};
