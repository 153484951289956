export default {
  "sidebar-section": "clT body-2",
  "sidebar-section-header": "clz body-1 mb-4",
  "providers-logos": "cla",
  "risks": "clH",
  "tag": "clO tag",
  "external-link-icon": "clm",
  "disclaimer": "clp",
  "divider": "clx"
};
