export default {
  "form": "Ag",
  "fieldset": "AD",
  "form-body": "AV",
  "conditional-select": "AL",
  "conditional-text": "AT",
  "group": "Az",
  "add-filter-button": "Aa btn btn--tertiary",
  "footer": "AH",
  "btn-icon": "AO",
  "add-icon": "Am",
  "cancel-button": "Ap btn",
  "form-header": "Ax",
  "align-start": "Aw",
  "align-right": "AN",
  "button-wrapper": "Av",
  "wrapper": "Ao",
  "label": "An body-2",
  "input-wrapper": "Ai",
  "input": "AA input-field body-2",
  "error": "AY",
  "error-message": "Ah"
};
