export default {
  "invite-review": "ccL",
  "invite-review-info": "ccT",
  "invite-review-info--invitee": "ccz",
  "disclaimer-button": "cca",
  "info-block": "ccH",
  "ev": "ccO",
  "tooltip": "ccm",
  "description": "ccp"
};
