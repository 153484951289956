/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { ProviderLogo } from 'qonto/react/components/insurance-hub/provider-logo';

export default class InsuranceHubProductSidebarComponent extends Component {
  @service intl;
  @service localeManager;

  providerLogo = ProviderLogo;

  get legalArticle() {
    return this.localeManager.locale === 'fr'
      ? 'https://legal.qonto.com/#information-prealable-a-la-conclusion-d-un-contrat-d-assurance-fr'
      : 'https://legal.qonto.com/#pre-contractual-information-for-the-conclusion-of-an-insurance-contract-en';
  }

  get disclaimer() {
    return this.intl.t('insurance-hub.details.sidebar.legal-notice-fr', {
      link: htmlSafe(
        `<a
          href=${this.legalArticle}
          target="_blank"
          rel="noopener noreferrer"
          class="body-link"
          data-test-disclaimer-link
        >${this.intl.t('insurance-hub.details.sidebar.link-text.legal-notice')}</a>`
      ),
      htmlSafe: true,
    });
  }
}
