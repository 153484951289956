export default {
  "step-container": "cBS",
  "budget-tooltip": "cBc",
  "content": "cBq",
  "summary-description": "cBZ",
  "detail": "cBR",
  "separator": "cBQ",
  "detail-value": "cBe",
  "color-secondary": "cBB",
  "beneficiary-info": "cBE",
  "currency": "cBd",
  "fees-tooltip": "cBI"
};
