export default {
  "guests-loading": "ctw",
  "header": "ctN",
  "body": "ctv",
  "members": "cto",
  "title": "ctn caption-bold",
  "placeholder-container": "cti",
  "icon": "ctA",
  "details": "ctY"
};
