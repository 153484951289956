export default {
  "integration-item": "cMG",
  "icon-container": "cMr",
  "content": "cMb",
  "title-container": "cMy",
  "new-badge": "cMU",
  "title": "cMj",
  "description": "cMW",
  "no-attributes": "cMu",
  "connect-cta": "cPS"
};
