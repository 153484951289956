export default {
  "sidebar": "ckL",
  "top-section": "ckT",
  "title": "ckz",
  "primary-action": "cka",
  "edit-icon": "ckH",
  "close-icon": "ckO",
  "box-wrapper": "ckm",
  "bottom-action": "ckp",
  "related-invoices": "ckx",
  "related-invoices-title": "ckw mb-16 caption-bold",
  "box": "ckN",
  "box-element": "ckv",
  "cancel-text": "cko",
  "box-header": "ckn",
  "row": "cki",
  "greyed-out": "ckA",
  "struck-through": "ckY",
  "share-mandate-link": "ckh"
};
